<template>
  <ListadoGeneral class="cards" :cargando="cargando" :msg-error="MsgError" :info="info" titulo="Gafetes" tipo="gafetes"
    tipo-icono="fas fa-id-badge" :MPaginacion="false"
    parrafo="La configuración de estos idiomas será para los datos que ellos no pueden cambiar, datos de la evaluación."
    :base-gets-listado="BaseGetsListado" :PermiteAgregar="false" :PermiteFiltrar="false" @actualiza="Actualiza"
    @agregar="Agregar" @editar="Editar" @borrar="Borrar" @reactivar="Reactivar"
    @update:datos-nuevo="(value) => (Datos = value)">
    <template #listado>
      <div class="card-columns-listado">
        <TablaConfIdioma :registros="info.listado" @guardar="GuardarCambios" />
      </div>
      <div class="d-flex justify-content-between">
        <router-link class="mt-2" :to="{}" @click="Regresar"><i
            class="fa fa-chevron-left me-2"></i>Cancelar</router-link>
        <!-- <button class="btn btn-success mb-0" @click="Guardar()">Guardar</button> -->
      </div>
    </template>
  </ListadoGeneral>
</template>

<script>
import TablaConfIdioma from "@/Vistas/AdminIdioma/Components/TablaConfIdioma.vue";
import ListadoGeneral from "@/Componentes/ListadoGeneral.vue";
//import Servicio from "@/api-services/colaboradores.service";
import Servicio from "@/api-services/idiomas.yacalificame.service";


export default {
  name: "ConfiguraciónIdioma",
  components: {
    ListadoGeneral,
    TablaConfIdioma,
  },
  prop: {
    datos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
    };
  },
  methods: {
    Agregar(Parametros) {
      Servicio.post(
        this,
        Parametros.registro,
        (response) => {
          if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de borrar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de Reactivar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    Actualiza(value) {
      this.BaseGetsListado = value;
      Servicio.get(this, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
    Guardar(){
      Servicio.put(this,this.info.listado, (response) => {
        console.debug("La info guardada", response.data)
      })
    },
    GuardarCambios(Fila){
      Servicio.put(this,Fila, (response) => {
        console.debug("La info guardada", response.data)
      })
    },
  },
};
</script>

<style lang="css" scoped></style>