<template>
  <div class="card pt-4">
    <div class="d-flex justify-content-around">
      <div class="col-6 border mb-3 pb-3">
        <GraficaEvaluaPorMeses />
      </div>
      <div class="col-6 border mb-3 pb-3">
        <!-- <GraficaEvaluaPorSemanas /> -->
      </div>
    </div>
    <!-- <div class="row">
      <div class="col border mb-3 pb-3">
        <GraficaEvaluaPorDias />
      </div>
    </div> -->
    <!-- <div class="children-container">
      <ChildComponent :data="chartData11" />
      <ChildComponent :data="chartData12" />
      <ChildComponent :data="chartData13" />
    </div> -->
  </div>
</template>

<script>
import GraficaEvaluaPorMeses from "../../../Componentes/Graficas/GraficaEvaluaPorMeses.vue";
/* import GraficaEvaluaPorSemanas from "../../../Componentes/Graficas/GraficaEvaluaPorSemanas.vue"; */
//import GraficaEvaluaPorDias from "../../../Componentes/Graficas/GraficaEvaluaPorDias.vue";
//import ChildComponent from "../../../Componentes/Graficas/ChildComponent.vue";

export default {
  components: {
    GraficaEvaluaPorMeses,
    /* GraficaEvaluaPorSemanas, */
    //GraficaEvaluaPorDias,
    //ChildComponent,
  },
  data() {
    return {
      chartData1: [10, 20, 30, 40, 50, 60, 70],
      chartData2: [50, 60, 70, 80, 90, 100, 110],
      chartData3: [30, 40, 50, 60, 70, 80, 90]
    }
  }
};
</script>

<style lang="scss" scoped>
</style>