<!-- @format -->

<template>
    <ListadoSelect
      v-model="Seleccionado"
      :tipo="tipo"
      tipo-icono="fas fa-sitemap"
      :funcion="Funcion"
     
    />
  </template>
  
  <script>
  import Service from "@/api-services/eTickets.colaboradores.service";
  import ListadoSelect from "@/Componentes/Listados/Select.vue";
  export default {
    name: "PersonalSelect",
    components: {
      ListadoSelect,
    },
    props: {
      tipo: {
        type: String,
        required: false,
        default: "Persona",
      },
    },
    data() {
      return {
  
        Seleccionado: null,
      };
    },
    computed: {
 
  
    },
    methods: {
      Funcion(Yo, response) {

        Service.getsSelect(Yo, false, response);
      },
    },
  
  };
  </script>