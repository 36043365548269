<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import MazSpinner from "maz-ui/components/MazSpinner";
import Chart from "chart.js/auto";
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";

const props = defineProps({
  id: {
    type: String,
    default: "doughnut-chart",
  },
  title: {
    type: String,
    default: "",
  },
  height: {
    type: String,
    default: "300",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Object,
      label: String,
      data: Array,
    },
  },
});

const emit = defineEmits(["Expandir"]);

const isExpanded = ref(false);

const infoeval = ref({ labels: [], datasets: [], evaluaciones: [] });

const cargando = ref(true);

const Expandir = () => {
  isExpanded.value = !isExpanded.value;
  if (isExpanded.value == false) {
    emit("Expandir", "Todas");
    return;
  }
  emit("Expandir", "Seguimiento");
};

const CrearGrafica = () => {
  let ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }

  new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: infoeval.value.labels,
      datasets: [
        {
          label: infoeval.value.datasets.label,
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: [
            "#34CEA0",
            "#182B4D",
            "#7C7C7C",
            "#2DCEC6",
            "#6BB056",
          ],
          data: infoeval.value.datasets.data,
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
};

onBeforeMount(() => {
  ServiciosDashboard.getsEvaluaciones(null, {}, (response) => {
    console.debug("Dashboard", response);
    let respuesta = response.data;
    infoeval.value = {
      labels: respuesta.etiquetas,
      datasets: respuesta.datasets,
      evaluaciones: respuesta.evaluaciones || [],
    };
    cargando.value = false;
  });
});

watch(cargando, (newValue, oldValue) => {
  if (newValue == false && infoeval.value.evaluaciones.length > 0) {
    setTimeout(() => {
      CrearGrafica();
    }, 1000);
  }
});
</script>

<template>
  <div>
    <div class="container">
      <div class="w-100 d-flex justify-content-between mt-3">
        <div class="" style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #21c997;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <i class="fa fa-qrcode" style="color: white; font-size: 1.5rem"></i>
        </div>
        <div class="text-center">
          <h5 class="">Seguimiento de evaluaciones</h5>
        </div>
        <div>
          <button style="opacity: 0;" class="btn rounded-circle mt-1">
            <i class="fa fa-expand" v-if="!isExpanded"></i>
            <i class="fa fa-compress" v-else></i>
          </button>
        </div>
      </div>
      <div class="w-100 mt-5">
        <div class="w-50 mx-auto my-auto d-flex justify-content-center">
          <MazSpinner v-if="cargando" :size="'48'" color="secondary"></MazSpinner>
        </div>
      </div>
      <div v-if="!cargando">
        <div v-if="infoeval.evaluaciones.length > 0">
          <div class="p-3">
            <div class="chart">
              <canvas :id="props.id" class="chart-canvas" :height="props.height"></canvas>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col d-flex justify-content-end align-items-end">
                <p class="text-end">Evaluaciones</p>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-end align-items-end">
                <div>
                  <p>{{ infoeval.evaluaciones }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 mt-5" v-else>
          <div class="w-100 mx-auto my-auto d-flex justify-content-center">
            <p>No hay evaluaciones</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
