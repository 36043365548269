<!-- @format -->

<template>
  <div class="mx-2 p-3 mx-lg-4 p-lg-4">
    <div class="d-flex justify-content-end">
      <div class="d-flex justify-content-end">
        <button style="color: white;" class="btn btn-default" :to="{}" :disabled="cargando" @click="MostrarFiltro = true">
          <i class="fas fa-filter"></i>
        </button>
        <button style="color: white;" class="btn btn-default" :to="{}" :disabled="cargando" @click="Actualiza()">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button style="color: white;" class="btn btn-default" :to="{}" :disabled="cargando" @click="MostrarExcel = true">
          <i class="far fa-chart-bar"></i>
        </button>
        <router-link style="color: white;" class="btn btn-default" :to="{ name: 'Topicos' }" :disabled="cargando">
          <i class="fas fa-cog"></i>
        </router-link>
        <button class="btn btn-primary" :to="{}" :disabled="cargando" @click="MostrarNuevoTicket = true">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <Cargando :cargando="cargando" :msg-error="MsgError">
      <div v-if="info != null">
        <div class="w-100 mx-0" style="overflow-x: auto">
          <div class="d-flex flex-row mb-3">
            <EstadoTicketCard
              v-for="estadoTicket in EstadosTickets"
               class="estado-ticket-card"
              :key="estadoTicket.id"
              :estado-ticket="estadoTicket"
              :tickets="info.listado"
              :colaboradores="info.colaboradores"
              @editar="Editar"
              @no-leido="NoLeido"
            />
          </div>
        </div></div
    ></Cargando>
    <NuevoTicketModal
      v-if="MostrarNuevoTicket"
      :cargando="cargando"
      :msg-error="MsgError"
      :mostrar-ir-a-edicion="false"
      @update:mostrar="(value) => (MostrarNuevoTicket = value)"
      @agregar="onAgregar"
    />
    <FoltroModal
      v-if="MostrarFiltro"
      v-model="BaseGetsListado"
      :cargando="cargando"
      :msg-error="MsgError"
      @update:mostrar="(value) => (MostrarFiltro = value)"
      @update:model-value="Actualiza()"
      @filtrar="onFiltrar"
    />
    <ExcelModal
      v-if="MostrarExcel"
      v-model="BaseGetsListado"
      :cargando="cargando"
      :msg-error="MsgError"
      @excel="onExcel"
      @update:mostrar="(value) => (MostrarExcel = value)"
    />
  </div>
</template>

<script>
import Servicio from "@/api-services/eTickets.tickets.service";
import ServicioExceles from "@/api-services/eTickets.exceles.service";
import EstadoTicketCard from "./Componentes/EstadoTicket.card.vue";
import NuevoTicketModal from "./Componentes/NuevoTicket.modal.vue";
import ExcelModal from "./Componentes/Excel.modal.vue";
import FoltroModal from "./Componentes/Filtro.modal.vue";
import Cargando from "@/Componentes/Cargando.vue";
export default {
  components: {
    EstadoTicketCard,
    Cargando,
    NuevoTicketModal,
    FoltroModal,
    ExcelModal,
  },
  props: {
    encuestaID: {
      type: String,
      default: "",
    },
  },
  emits: ["update:encuestaID", "editar"],
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 50,
        Filtro: "",
      },

      MostrarNuevoTicket: false,
      MostrarFiltro: false,
      MostrarExcel: false,
    };
  },
  computed: {
    EstadosTickets() {
      if (this.info == null) return [];
      return this.info.estadosTickets.filter((x) => !x.borrado);
    },

    EstiloCampo() {
      return "col-lg-3 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
  },
  created() {},
  mounted() {
    this.Actualiza(this.BaseGetsListado);
  },
  methods: {
    Editar(ticket) {
      console.log("Editar", ticket);
      //this.$emit("editar", this.ticket);
      this.$router.push({ path: `/tickets/${ticket.id}` });
    },
    NoLeido(ticket) {
      //this.$emit("noLeido", this.ticket);
    },
    Actualiza(value) {
      if (value != null) this.BaseGetsListado = value;
      //console.log("Actualiza", value);
      Servicio.GetsEnriquecido(this, this.BaseGetsListado, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    onAgregar(Datos) {
      console.log("onAgregar", Datos);
      Servicio.post(this, Datos, (response) => {
        this.Actualiza();
        this.MostrarNuevoTicket = false;
      });
    },
    onExcel(Datos) {
      console.log("onExcel", Datos);
      switch (Datos.Excel) {
        case "Detallado":
          ServicioExceles.GetsDetallado(this, Datos, (response) => {});
          break;
        default:
          ServicioExceles.GetsXEstadosTickets(this, Datos, (response) => {});
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .estado-ticket-card {
    min-width: 95% !important;
    max-width: 95% !important;
  }
}</style>
