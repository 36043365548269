<template>
  <div class="py-4 container-fluid">
    <MazSpinner class="w-100 d-flex justify-content-center" v-if="cargando" color="secondary" :size="'48'" />
    <div v-else class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="mt-4 mb-3 col-12 col-lg-12 mb-sm-5">
              <div class="multisteps-form__progress">
                <button class="multisteps-form__progress-btn js-active" type="button" title="Forma entrega"
                  disabled :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0">
                  <i class="fas fa-file-export fa-2x"></i>
                  <br />
                  <p>Solicitar</p>
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Media"
                  disabled :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1">
                  <i class="fas fa-check-circle fa-2x"></i>
                  <br />
                  <p>Solicitud Recibida</p>
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Socials"
                  disabled :class="activeStep >= 2 ? activeClass : ''"
                  @click="activeStep = 2">
                  <i class="fas fa-id-card fa-2x"></i>
                  <br />
                  <p>Creando gafete</p>
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Pricing"
                  disabled :class="activeStep >= 3 ? activeClass : ''"
                  @click="activeStep = 3">
                  <i class="fas fa-shipping-fast fa-2x"></i>
                  <br />
                  <p>Gafete enviado</p>
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Pricing"
                  disabled :class="activeStep >= 4 ? activeClass : ''"
                  @click="activeStep = 4">
                  <i class="fas fa-box-open fa-2x"></i>
                  <br />
                  <p>Gafete entregado</p>
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-12">
              <form class="mb-8 multisteps-form__form">
                <!--single form panel-->
                <Step1Solicitar v-if="activeStep == 0" :class="activeStep === 0 ? activeClass : ''" @next-step="nextStep"
                  :selected-radio="selectedRadio" @change-radio="handleRadioChange" />
                <!--single form panel-->
                <!-- <layout-forma-entrega
                  :class="activeStep === 0 ? activeClass : ''"
                  @next-step="nextStep"
                /> -->
                <!--single form panel-->
                <Step2SolicitudRecibida v-if="activeStep == 1" :class="activeStep === 1 ? activeClass : ''" @next-step="nextStep"
                  @prev-step="prevStep" />
                <!--single form panel-->
                <Step3CreandoGafete v-if="activeStep == 2" :class="activeStep === 2 ? activeClass : ''" @next-step="nextStep"
                  @prev-step="prevStep" :FechaSolicitud="FechaSolicitud" :FechaEntrega="FechaEntrega" />
                <!--single form panel-->
                <Step4GafeteEnviado v-if="activeStep == 3" :class="activeStep === 3 ? activeClass : ''" @prev-step="prevStep" />
                <!--single form panel-->
                <Step5GafeteEntregado v-if="activeStep == 4" :fechaSolicitado="FechaSolicitud" :fechaEntrega="FechaEntrega" :receptor="Receptor" :class="activeStep === 4 ? activeClass : ''" @next-step="nextStep"
                  @prev-step="prevStep" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es-mx");
import { ref } from "vue";
import { useStore } from "vuex";
import MazSpinner from "maz-ui/components/MazSpinner";
import Step1Solicitar from "./Step1Solicitar.vue";
//import LayoutFormaEntrega from './LayoutFormaEntrega.vue';
import Step5GafeteEntregado from "./Step5GafeteEntregado.vue";
import Gafetes from "@/api-services/gafetes.yacalificame.service";
import Step2SolicitudRecibida from "./Step2SolicitudRecibida.vue";
import Step3CreandoGafete from "./Step3CreandoGafete.vue";
import Step4GafeteEnviado from "./Step4GafeteEnviado.vue";
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  components: {
    MazSpinner,
    Step1Solicitar,
    //LayoutFormaEntrega,
    Step5GafeteEntregado,
    Step2SolicitudRecibida,
    Step3CreandoGafete,
    Step4GafeteEnviado
  },
  data() {
    return {
      activeClass: "js-active position-relative",
      activeStep: ref(0),
      formSteps: 3,
      selectedRadio: null,
      cargando: true,
      FechaSolicitud:null,
      FechaEntrega:null,
      Receptor: null,
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep++;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    handleRadioChange(value) {
      this.selectedRadio = value;
    }
  },
  beforeMount() {
    Gafetes.get(this, this.$route.params.id, (response) => {
      console.debug("Este es el estado de su gafete", response.data)
      if (!response.data == "") {    
        let estado = response.data.eEstatusEnvio
        this.FechaSolicitud = moment(response.data.fechaSolicitado).format("DD/MMMM/YYYY  HH:MM ")
        this.FechaEntrega = moment(response.data.fechaEntrega).format("DD/MMMM/YYYY")
        this.Receptor = response.data.receptor
        switch (estado) {
          case "RegistrarPago":
            this.activeStep = 1
            break;
          case "Imprimir":
            this.activeStep = 2
            break;
          case "Varificar":
            this.activeStep = 2
            break;
          case "Entregar":
            this.activeStep = 3
            break;
          case "Entregado":
            this.activeStep = 4
            break;
          default:
            break;
        }
      }
    })
  },
  mounted() {
    const store = useStore();
    store.state.layout = "custom";
    store.state.showNavbar = false;
    store.state.showFooter = true;
    store.state.hideConfigButton = true;
    store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    const store = useStore();
    store.state.isAbsolute = false;
    store.state.layout = "default";
    store.state.showNavbar = true;
    store.state.showFooter = true;
    store.state.hideConfigButton = false;
    document.body.classList.remove("new-product");
  },
};
</script>