<template>
  <CardTitulo titulo="Datos acceso" id="datos-acceso">
    <div class="row">
      <div class="col-12">
        <p :class="EstiloCampo">
          Es importante que llenes correctamente estos datos, ya que con ellos
          podrán tener acceso a la plataforma y poder observar sus
          calificaciones, así como también poder comentar sobre ellas.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p class="parrafosDatos" style="color: #2dcec6 !important">
          *Servirá para tu inicio de sesión
        </p>
        <MazInput
          v-model="localEmailPersonal"
          type="text"
          label="Email personal"
          color="secondary"
          @update:model-value="(value) => update('correo', value)"
        />
      </div>
      <div class="col-md-5">
        <p class="parrafosDatos" style="color: #2dcec6 !important">
          *Servirá para poder recibir el acceso a tu plataforma
        </p>
        <Telefono
          v-model="localMovilPersonal"
          label="Móvil personal"
          :class="EstiloCampo"
          color="secondary"
          @update:model-value="(value) => update('telMovilPersonal', value)"
        >
          <template v-if="BuscarCP" #right-icon>
            <router-link
              :to="{}"
              class="text-primary small p-2"
              @click="OnMostrarBuscarCP"
              >No conozco mi CP</router-link
            >
          </template>
          <a href="/de" target="_blank" rel="noopener noreferrer">
            <div class="tooltip-container">
              Enviar SMS
              <span class="tooltip"
                >Se le enviara su enlace de acceso a la plataforma, en su nuevo
                número.</span
              >
            </div>
          </a>
        </Telefono>
      </div>
      <div class="col-md-2">
        <a href="/de" target="_blank" rel="noopener noreferrer">
          <div class="tooltip-container">
            Enviar SMS
            <span class="tooltip"
              >Se le enviara su enlace de acceso a la plataforma, en su nuevo
              número.</span
            >
          </div>
        </a>
      </div>
    </div>
    <h4 class="mt-3" style="color: #2dcec6 !important">
      Accesos a la plataforma
    </h4>
    <div class="row mb-3">
      <div class="col-6 col-sm-6">
        <MazRadio
          value="Administrador"
          name="RadioAccesoAdmin"
          color="secondary"
          v-model="localAcceso"
          @update:model-value="(value) => update('perfil', value)"
        >
          Administrador
        </MazRadio>
      </div>
      <div class="col-6 col-sm-6">
        <MazRadio
          value="Colaborador"
          name="RadioAccesoAutoservicio"
          color="secondary"
          v-model="localAcceso"
          @update:model-value="(value) => update('perfil', value)"
        >
          Autoservicio
        </MazRadio>
      </div>
      <!-- <p>{{localAcceso}}</p> -->
    </div>
  </CardTitulo>
</template>

<script>
import { watch } from "vue";
import CardTitulo from "../../../Componentes/CardTitulo.vue";
import Telefono from "@/Componentes/Telefono.vue";
import MazRadio from "maz-ui/components/MazRadio";

export default {
  components: {
    CardTitulo,
    Telefono,
    MazRadio,
  },
  props: {
    correo: {
      type: String,
      default: "",
    },
    telMovilPersonal: {
      type: String,
      default: "",
    },
    perfil: {
      type: String,
      default: "",
    },
  },
  emits: ["update:correo", "update:telMovilPersonal", "update:perfil"],
  data() {
    return {
      localEmailPersonal: this.correo,
      localMovilPersonal: this.telMovilPersonal,
      localAcceso: this.perfil,
    };
  },

  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.correo,
      (newVal) => {
        this.localEmailPersonal = newVal;
      }
    );
    watch(
      () => this.telMovilPersonal,
      (newVal) => {
        this.localMovilPersonal = newVal;
      }
    );
    watch(
      () => this.perfil,
      (newVal) => {
        this.localPerfil = newVal;
      }
    );
  },

  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
  },
  /* mounted() {
    this.$nextTick(() => {
      const tooltipTriggerEl = document.querySelector('[data-bs-toggle="tooltip"]');
      if (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl);
      }
    });
  }, */
};
</script>
<style>
.parrafosDatos {
  color: #2dcec6;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;
}

.tooltip {
  visibility: hidden;
  width: 250px;
  background-color: #2dcec6;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
@media only screen and (min-width: 1200px) {
  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-top: 3rem;
    text-decoration: underline;
  }
}
</style>

 