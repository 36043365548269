<!-- @format -->

<template>
  <div>
    <ListadoGeneral
      :cargando="cargando"
      :msg-error="MsgError"
      :info="info"
      titulo="Colaboradores"
      tipo="colaborador"
      tipo-icono="fas fa-address-book"
      parrafo="Este es el listado de todos tus colaboradores o empleados, puedes importar sus datos, capturarlos manualmente, pedirles que se auto registren o filtrarlos."
      nuevo-type="large"
      :base-gets-listado="BaseGetsListado"
      @actualiza="Actualiza"
      @agregar="Agregar"
      @borrar="Borrar"
      @reactivar="Reactivar"
      @update:datos-nuevo="(value) => (Datos = value)"
    >
      <template #listado="{ eventos }">
        <div class="card-columns-listado">
          <CardRegistro
            v-for="registro in info.listado"
            :key="registro.id"
            :registro="registro"
            @editar="eventos.onEditar"
            @borrar="eventos.onBorrar"
            @reactivar="eventos.onReactivar"
          />
        </div>
        <p>{{ registro }}</p>
      </template>
      <template #nuevo>
        <div>
          <div class="row">
            <MazInput
              v-model="Datos.Nombre"
              :class="EstiloCampo"
              type="text"
              label="Nombre(s)"
              color="secondary"
              required
            />
            <MazInput
              v-model="Datos.ApellidoP"
              :class="EstiloCampo"
              type="text"
              label="Apellido Paterno"
              color="secondary"
              required
            />
            <MazInput
              v-model="Datos.ApellidoM"
              :class="EstiloCampo"
              type="text"
              label="Apellido Materno"
              color="secondary"
            />
          </div>
          <div class="row">
            <MazInput
              v-model="Datos.LinkID"
              :class="EstiloCampo"
              required
              type="text"
              label="Id único"
              color="secondary"
            />
            <PaisSelect
              v-model="Datos.Nacionalidad"
              :nacionalidad="true"
              :class="EstiloCampo"
              label="Nacionalidad"
              campo-valor="iso3"
              color="secondary"
            />

            <CURPInput
              v-model="Datos.curp"
              :class="EstiloCampo"
              label="CURP"
              @model-value="CurpActualizado"
              color="secondary"
            />
          </div>
          <div class="row">
            <GenerosSelect v-model="Datos.genero" :class="EstiloCampo" color="secondary" />

            <CorreoInput
              v-model="Datos.correo"
              label="Email personal"
              :class="EstiloCampo"
              color="secondary"
              required
            />
            <Telefono
              v-model="Datos.telMovilPersonal"
              label="Móvil personal"
              :class="EstiloCampo"
              color="secondary"
            />
          </div>
          <div class="row">
            <PuestosSelect v-model="Datos.puestoID" :class="EstiloCampo" color="secondary" required />
            <UbicacionesSelect
              v-model="Datos.ubicacionID"
              :class="EstiloCampo"
              color="secondary"
            />
            <SelectEncuestas v-model="Datos.encuestaID" :class="EstiloCampo" color="secondary"/>
          </div>
        </div>
      </template>
    </ListadoGeneral>
  </div>
</template>

<script>
import ListadoGeneral from "../../Componentes/ListadoGeneral.vue";
import Servicio from "@/api-services/colaboradores.yacalificame.service";
import CardRegistro from "./Componentes/CardRegistro.vue";
import PuestosSelect from "@/Vistas/Puestos/Select.vue";
import UbicacionesSelect from "@/Vistas/Ubicaciones/Select.vue";
import GenerosSelect from "@/Componentes/GenerosSelect.vue";
import PaisSelect from "@/Componentes/PaisSelect.vue";
import Telefono from "@/Componentes/Telefono.vue";
import CURPInput from "@/Componentes/CURPInput.vue";
import CorreoInput from "@/Componentes/CorreoInput.vue";
import SelectEncuestas from "@/Vistas/Encuestas/Select.vue";

export default {
  name: "ColaboradoresListado",
  components: {
    ListadoGeneral,
    CardRegistro,
    PuestosSelect,
    UbicacionesSelect,
    GenerosSelect,
    Telefono,
    PaisSelect,
    CURPInput,
    CorreoInput,
    SelectEncuestas,
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
    };
  },
  computed: {
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 mb-3 px-2 mb-campo";
    },
  },
  methods: {
    Agregar(Parametros) {
      //   console.log("Agregar", JSON.stringify( Parametros));
      //return;
      Servicio.post(
        this,
        Parametros.registro,
        (response) => {
          if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
      Evento de borrar registro
      @param Parametros{
        registro: registro a borrar
        OnBorrado: callback para ejecutar después de borrar
      }
    */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        {},
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
      Evento de Reactivar registro
      @param Parametros{
        registro: registro a borrar
        OnBorrado: callback para ejecutar después de borrar
      }
    */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        {},
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    Actualiza(value) {
      this.BaseGetsListado = value;
      //console.log("Actualiza", value);
      Servicio.GetsEnriquecido(this, value, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
    CurpActualizado(valor) {
      console.log(valor);
      // this.Datos.Curp = valor;
      console.log(this.Datos.Curp);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-columns-listado {
  display: flex;
  flex-wrap: wrap;
  margin: -7px; /* Ajusta este valor para controlar el espaciado general entre tarjetas */
}

.card {
  display: flex; /* Habilita flexbox para el contenido de la tarjeta */
  margin: 7px; /* Espaciado alrededor de cada tarjeta */
  flex-basis: calc(
    100% - 14px
  ); /* Ajusta este valor según el número deseado de tarjetas por fila */
  min-height: 5.2em;
}

@media (min-width: 35em) {
  .card {
    flex-basis: calc(100% / 2 - 14px); /* 2 tarjetas por fila */
  }
}

@media (min-width: 65em) {
  .card {
    flex-basis: calc(100% / 3 - 14px); /* 3 tarjetas por fila */
  }
}

@media (min-width: 97em) {
  .card {
    flex-basis: calc(100% / 4 - 14px); /* Mantenemos 4 tarjetas por fila */
  }
}

@media (min-width: 114em) {
  .card {
    flex-basis: calc(100% / 5 - 14px); /* 5 tarjetas por fila */
  }
}

@media (min-width: 130em) {
  .card {
    flex-basis: calc(100% / 6 - 14px); /* 6 tarjetas por fila */
  }
}
@media (min-width: 150em) {
  .card {
    flex-basis: calc(100% / 7 - 14px); /* 7 tarjetas por fila */
  }
}
</style>
