<!-- @format -->

<template>
  <div class="card p-3 mb-3" :style="estilo">
    <router-link :to="{}" data-effect="fadeOut" @click="Editar">
      <div class="d-flex justify-content-between">
        <div>
          <h5 class="mb-1 text-truncate">No.: {{ ticket.linkID }}</h5>
          <h5 class="mb-1 text-truncate">Fecha: {{ formatDateToCustomString(ticket.creado) }}</h5>
          <h4 style="max-width: 195px;" class="mb-1 text-truncate">ID: {{ colaborador.linkID }} {{ colaborador.etiqueta }}</h4>
        </div>
        <img class="img-fluid rounded-circle" style="width: 72px" :src="FotoURL" alt="profile card"/>
      </div>
      <hr />
        <div class="position-relative p-0 ">
          <div class="d-flex justify-content-between">
            <h4>{{ ticket.etiqueta }}</h4>
            <el-tooltip :content="`Creado por: ${CreadoPor.etiqueta}`" placement="top" effect="customized" > 
              <img class="img-fluid rounded-circle mt-n1  " style="width: 24px; height: 24px;" :src="CreadoPor.urlMiniatura" alt="profile card" />
            </el-tooltip>
          </div>
          <p class="text-wrap">{{ ticket.descripcion }}</p>
          <span
            class="badge badge-md badge-circle badge-floating badge-info border-white position-absolute top-100 start-100 translate-middle"
            >{{ticket.noComentarios}}</span>
        </div>

    </router-link>
  </div>
</template>

<script>
import Servicio from "@/api-services/colaboradores.service";
import { ElTooltip } from 'element-plus';

export default {
  components: {ElTooltip,},
  props: {
    estilo: {
      type: String,
      default: "",
    },
    colaboradores: {
      type: Array,
      default: () => {
        return [];
      },
    },
    ticket: {
      type: Object,
      default: () => {
        return {
          id: "",
          linkID: "",
          etiqueta: "",
          descripcion: "",
          color: "",
        };
      },
    },
    minWidth: {
      type: Number,
      default: 400,
    },
  },
  emits: ["editar", "noLeido"],

  data() {
    return {};
  },
  computed: {
    FotoURL() {
      let img = Servicio.FotoURL(this.colaborador);
      return img;
    },
    colaborador() {
      if (this.colaboradores == null || this.colaboradores.length == 0) return {};
      return this.colaboradores.find((x) => x.id == this.ticket.colaboradorID);
    },
    CreadoPor(){
      let creador = this.colaboradores.find((x) => x.id == this.ticket.colaboradorIDCreo )
      return creador;
    },
  },
  created() {},
  methods: {
    Editar() {
      //  console.log("Editar", ticket);
      this.$emit("editar", this.ticket);
    },
    NoLeido() {
      this.$emit("noLeido", this.ticket);
    },
    formatDateToCustomString(dateString) {
      const date = new Date(dateString);
      const today = new Date();
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const timeString = date.toLocaleTimeString("en-US", options).replace(/\s/g, "").toUpperCase();

      let formattedDate = "";

      // Check if the date is today
      const isToday = date.toDateString() === today.toDateString();

      if (!isToday) {
        const day = date.getDate();
        const month = date.toLocaleString("es-MX", { month: "long" });
        formattedDate += `${day}/${month.charAt(0).toUpperCase() + month.slice(1)}`;
      }

      // Check if the year is the same as the current year
      const isSameYear = date.getFullYear() === today.getFullYear();

      if (!isSameYear) {
        const year = date.getFullYear();
        formattedDate += `/${year}`;
      }

      return `${timeString.toLowerCase()} ${formattedDate}`;
    },
  },
};
</script>

<style lang="scss" >
.el-popper.is-customized {
  padding: 6px 12px;
  border-radius: 10px;
  background-color: white;
}

.el-popper.is-customized .el-popper__arrow::before {
  border-radius: 10px;
  background-color: white;
  right: 0;
}
</style>
