/** @format */

import Base from "@/api-services/base";

import YaCalificaMe from "@/api-services/eTickets.service.js";
const BaseURL = `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/UbicacionesYaCa`;

export default {
  GetsResponse: null,
  Listado: [],

  get(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
 
};
