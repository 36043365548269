<template>
  <p class="parrafo__datos">Datos personales que le solicitarás a tu cliente</p>
  <div class="row">
    <div :class="EstiloCampo">
      <Campos
        titulo="Nombre"
        v-model="localNombre"
        @update:model-value="(value) => update('campoNombre', value)"
      />
    </div>
    <div :class="EstiloCampo">
      <Campos
        titulo="Correo electrónico"
        v-model="localCorreoElectronico"
        :mostrarNoSolicitar="false"
        @update:model-value="(value) => update('campoEmail', value)"
      />
    </div>
    <div :class="EstiloCampo">
      <Campos
        titulo="Fecha nacimiento"
        v-model="localFechaNac"
        :mostrarNoSolicitar="false"
        @update:model-value="(value) => update('campoFecha', value)"
      />
    </div>
  </div>

  <ConfiguracionPregunta
    class="mb-3"
    v-for="(registro, index) in localListadoPreguntas"
    :key="registro.id"
    :NP="index"
    v-model="localListadoPreguntas[index]"
    @borrar="Borrar"
    @ActualizaOpciones="(Opciones)=>ActualizaOpciones(Opciones,localListadoPreguntas[index])"
  />
  <AgregarPregunta @click="OnAgregarPregunta"  class="mt-3"/>
  <ModalBorrar
    v-if="MostrarBorrar"
    :tipo="tipo"
    :etiqueta="RegistroABorrar.etiqueta"
    :reactivar="RegistroABorrar.borrado"
    @update:mostrar="(value) => (RegistroABorrar = null)"
    @borrar="(value) => BorrarSinPreguntar()"
  />
</template>

<script>
import AgregarPregunta from "@/Vistas/Encuestas/Componentes/AgregarPregunta.vue";
import Campos from "@/Vistas/Encuestas/Componentes/Campos.vue";
import ConfiguracionPregunta from "./ConfiguracionPregunta.vue";
import ModalBorrar from "@/Componentes/Listados/ModalBorrar.vue";

export default {
  components: {
    AgregarPregunta,
    Campos,
    ConfiguracionPregunta,
    ModalBorrar,
  },
  props: {
    campoNombre: {
      type: String,
      default: "",
    },
    campoEmail: {
      type: String,
      default: "",
    },
    campoFecha: {
      type: String,
      default: "",
    },
    listadoPreguntas: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
  },

  emits: [
    "update:campoNombre",
    "update:campoEmail",
    "update:campoFecha",
    "update:listadoPreguntas",
    "update:datos-nuevo",
    "borrar",
    "editar",
  ],
  data() {
    return {
      Ultimo: 0,
      Seleccion: "Preguntas",
      localNombre: this.campoNombre,
      localCorreoElectronico: this.campoEmail,
      localFechaNac: this.campoFecha,
      localListadoPreguntas: this.ActualizaPreguntas(this.listadoPreguntas),
      RegistroABorrar: null,

      eventos: {
        onEditar: this.Editar,
        onBorrar: this.Borrar,
        onReactivar: this.Reactivar,
      },
    };
  },
  watch: {
    listadoPreguntas(valor) {
      this.localListadoPreguntas = this.ActualizaPreguntas(this.listadoPreguntas);
    },
  },
  computed: {
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 mb-campo";
    },
    
  },
  mounted() {

  },
  methods: {
    ActualizaPreguntas(listadoPreguntas) {
      var R = [];
      console.debug(R);
      if (listadoPreguntas != null) R = listadoPreguntas;
      var Yo = this;
      R.forEach((e) => {
        console.debug(e);
        if (e.id == null) {
          e.id = `ID${Yo.Ultimo}`;
          Yo.Ultimo++;
        }
      });
      console.debug(R);
      return R;
    },
    onBorrar(registro) {
      console.log("onBorrar", registro);
    },
    ActualizaDatosNuevo(Datos) {
      this.$emit("update:datos-nuevo", Datos);
    },
    Borrar(index) {
      this.localListadoPreguntas.splice(index, 1);
      //this.$emit("borrar", registro);
    },
    BorrarSinPreguntar() {
      // Encuentra el índice del elemento a eliminar
      let index = this.localListadoPreguntas.indexOf(this.RegistroABorrar);

      // Si el elemento está en el array, elimínalo
      if (index !== -1) {
        this.localListadoPreguntas.splice(index, 1);
      }
      this.RegistroABorrar = null;
      this.localListadoPreguntas
      this.$emit("update:listadoPreguntas", this.localListadoPreguntas);

      /*
      this.$emit("borrar", {
        registro: this.RegistroABorrar,
        OnBorrado: this.OnBorrado,
      });*/
    },

    OnBorrado(response) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.Actualiza();
          this.RegistroABorrar = null;
        });
      }, 100);
      /*    this.$nextTick(() => {
        this.Actualiza();
        this.RegistroABorrar = null;
      });*/
    },
    OnError(MsgError, Error) {
      console.debug("Error", MsgError);
      if (Error && Error.response)
        console.debug("Error.response", Error.response.data);
    },
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    updateListado() {
      this.$emit(`update:listadoPreguntas`, this.localListadoPreguntas);
    },
    ActualizaDatos(Datos) {
      this.$emit("update:model-value", Datos);
    },
    OnAgregarPregunta() {
      this.localListadoPreguntas.push({});
      this.ActualizaPreguntas(this.localListadoPreguntas);
      this.$emit("update:listadoPreguntas", this.localListadoPreguntas);
    },

    ActualizaOpciones(Opciones, Objeto){
      console.debug("Estas son las opciones actualizadas",Opciones,Objeto)
   //   Objeto.opciones = Opciones;
    },

  },
};
</script>

<style lang="scss" scoped>
</style>