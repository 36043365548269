<!-- @format -->

<template>
  <div class="card shadow-lg bg-dark p-3 m-3" :style="Estilo">
    <h4 :style="EstiloTitulo">{{ estadoTicket.etiqueta }}</h4>
    <hr />
    <div>
      <TicketCard
        v-for="ticket in ticketsFiltrados"
        :key="ticket.id"
        :ticket="ticket"
        :colaboradores="colaboradores"
        :estilo="estiloTicket"
        @editar="Editar"
        @no-leido="NoLeido"
      />
    </div>
  </div>
</template>

<script>
import TicketCard from "./Ticket.card.vue";
export default {
  components: {
    TicketCard,
  },
  props: {
    colaboradores: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tickets: {
      type: Array,
      default: () => {
        return [];
      },
    },

    estadoTicket: {
      type: Object,
      default: () => {
        return {
          id: "",
          etiqueta: "",
          descripcion: "",
          color: "",
        };
      },
    },
    minWidth: {
      type: Number,
      default: 400,
    },
  },
  emits: ["editar", "noLeido"],

  data() {
    return {};
  },
  computed: {
    estiloTicket() {
      var R = `border-radius: 17px;border:0px solid ${this.estadoTicket.color}; border-left: 10px solid ${this.estadoTicket.color}`;
      return R;
    },
    ticketsFiltrados() {
      return this.tickets.filter((x) => x.estadoTicketID == this.estadoTicket.id);
    },
    EstiloTitulo() {
      return `color: ${this.estadoTicket.color} !important`;
    },
    Estilo() {
      return `min-width: ${this.minWidth}px; width: ${this.minWidth}px;`;
    },
  },
  created() {},
  methods: {
    Editar(ticket) {
      console.log("Editar", ticket);
      this.$emit("editar", ticket);
    },
    NoLeido(ticket) {
      this.$emit("noLeido", ticket);
    },
  },
};
</script>

<style lang="scss" scoped></style>
