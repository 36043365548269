<template>
  <div class="border rounded" style="background-color: #e9ecef">
    <div class="container mt-4">
      <div class="d-flex justify-content-around">
        <div class="col text-start">
          <h3 class="text-secondary">Tabla de idiomas</h3>
        </div>

        <div style="gap:30px" class="d-flex justify-content-between">
          <button class="col-1 mb-3">
            <i class="fa fa-eye icon text-primary"></i>
          </button>
          <!-- <PaisSelect class="col-7 ms-3 me-2" /> -->
          <div class="col">
            <NavbarIdiomas
              class="w-90" @AgregarIdioma="AgregarIdioma"
            />
          </div>
        </div>
      </div>
      <div
        style="gap: 20px; overflow-x: auto; overflow-y: auto"
        class="w-100 d-flex justify-content-between mt-3 mb-3"
      >
        <CardIdioma
          class="w-90"
          v-for="Idioma in IdiomasDisponibles"
          :key="Idioma"
          :Idioma="Idioma"
          :Info="listadoPreguntas"
          :Idiomas="IdiomasDisponibles"
          @updateInfo="editarInfoOriginal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardIdioma from "./CardIdioma.vue";
import Servicio from "@/api-services/encuestas.service";
import NavbarIdiomas from "./NavbarIdiomas.vue";
// import PaisSelect from "@/Componentes/PaisSelect.vue";

export default {
  name: "EdicionIdiomas",
  components: {
    CardIdioma,
    NavbarIdiomas,
  },
  props: {
    listadoPreguntas: {
      type: Array,
      default: () => [],
    },
    listadoIdiomas: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    opciones: {
      type: Object,
      default: () => ({}),
    },
    idiomas: {
      type: Array,
      required: true,
    },
    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    info: {
      type: Object,
      default: null,
    },
    cargando: {
      type: Boolean,
      default: true,
    },
    msgError: {
      type: String,
      default: "",
    },
    titulo: {
      type: String,
      default: "Listado General",
    },
    parrafo: {
      type: String,
      default: "Parrafo General",
    },
    baseGetsListado: {
      type: Object,
      default: () => {
        return {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 10,
          Filtro: "",
        };
      },
    },
  },
  emits: [
    "update:campoNombre",
    "update:campoEmail",
    "update:campoFecha",
    "update:listadoPreguntas",
    "update:datos-nuevo",
    "borrar",
    "editar",
    "agregar",
    "editarInfoOriginal",
  ],
  data() {
    return {
      localListadoPreguntas: this.ActualizaPreguntas(this.listadoPreguntas),
      localListadoIdiomas: this.listadoPreguntas,
      selectedLanguage: "es-ES", // idioma seleccionado,
      IdiomasDisponibles: [],
      isSidebarOpen: false,
    };
  },
  watch: {
    listadoPreguntas(Valor){
      let pregunta = Valor[0].contenido
      console.debug("Esta es la pregunta", pregunta)
      this.IdiomasDisponibles = []
      for (let index = 0; index < pregunta.length; index++) {
        this.IdiomasDisponibles.push(pregunta[index].idioma);
      }
  } },
  beforeMount() {
    if (this.listadoPreguntas != null && this.listadoPreguntas.length > 0) {
      let pregunta = this.listadoPreguntas[0].contenido;
      for (let index = 0; index < pregunta.length; index++) {
        this.IdiomasDisponibles.push(pregunta[index].idioma);
      }
    }
  },
  methods: {
    ActualizaPreguntas(listadoPreguntas) {
      var R = [];
      if (listadoPreguntas != null) R = listadoPreguntas;
      var Yo = this;
      R.forEach((e) => {
        if (e.id == null) {
          e.id = `ID${Yo.Ultimo}`;
          Yo.Ultimo++;
        }
      });
      return R;
    },
    getPreguntaContenido(pregunta, idioma) {
      const contenido = pregunta.contenido.find((c) => c.idioma === idioma);
      return contenido ? contenido.contenido : "";
    },
    Borrar(registro) {
      this.$emit("borrar", registro);
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    AgregarIdioma(Pais){
      let IdiomasAct = structuredClone(this.listadoPreguntas)
      for (let index = 0; index < IdiomasAct.length; index++) {
        let ContPreguntas = IdiomasAct[index].contenido
        ContPreguntas.push({
          idioma:Pais,
          contenido:"",
        })
      }
      this.$emit("update:listadoPreguntas",IdiomasAct)
    },
    editarInfoOriginal(InfoDelHijo){
      this.$emit("editarInfoOriginal",InfoDelHijo)
    }
  },
};
</script>

<style scoped>
</style>
