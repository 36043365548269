<template>
  <div>
    <div class="d-flex justify-content-center text-center mb-2">
      <h4>{{ ObtenBandera(Idioma) + " " + Idioma }}</h4>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(Pregunta, index) in Preguntas" :key="index">
          <td>
            <!-- @update:Opciones="(value) => ObOpciones.Info[index].contenido = value" -->
            <CardPregunta :PreguntaObj="Pregunta" :Opciones="Info[index].opciones" :Idioma="Idioma"
              @update:PreguntaObj="(value) => Pregunta.contenido = value"
              @update:Opciones="(value) => VistaPrevia(value, index)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { countryCodeToUnicodeFlag } from "maz-ui";
import CardPregunta from './CardPregunta.vue';

export default {
  name: "EdicionIdioma",
  components: { CardPregunta },
  props: {
    Idioma: { type: String, default: "" },
    Info: { type: Object, default: null },
    Idiomas: { type: Array, default: null },
    info: { type: Array, default: () => [] },
  },
  data() {
    return {
      Preguntas: [],
      Opciones: [],
      MuestraEdicion: false,
      Editar: false,
      editableInfo: [...this.info],
    };
  },
  beforeMount() {
    for (let index = 0; index < this.Info.length; index++) {
      let pregunta = this.Info[index].contenido.find(
        (x) => x.idioma === this.Idioma
      );
      if (pregunta) {
        this.Preguntas.push(pregunta);
      }
    }
    this.VerificarIdiomas(this.Info, this.Idiomas)
  },
  methods: {
    ObtenBandera(Pais) {
      if (Pais === "es-ES") {
        let bandera = "MX";
        return countryCodeToUnicodeFlag(bandera);
      }

      let bandera2 = Pais.split("-")[1];
      return countryCodeToUnicodeFlag(bandera2);
    },
    VerificarIdiomas(Lista, Idiomas) {
      Lista.forEach(element => {
        if (element.opciones.length > 0) {
          element.opciones.forEach(options => {
            let idiomasActuales = options.idiomas.map(idioma => idioma.idioma);
            Idiomas.forEach(idioma => {
              if (!idiomasActuales.includes(idioma)) {
                options.idiomas.push({ idioma: idioma, contenido: "" });
              }
            });
          })
        }
      });
    },
    ActualizaOpciones(index, value) {
      // eslint-disable-next-line vue/no-mutating-props
      this.Info[index].opciones = value;
    },
    updateInfo(nuevaInfo) {
      this.$emit('updateInfo', nuevaInfo)
    },
    
    VistaPrevia(Objeto, index) {
      console.debug(Objeto)
      // let preguntasaux = this.Info[index].opciones.filter((idiomas) => {
      //   return idiomas.idiomas.includes((idioma) => idioma.idioma != "es-ES");
      // })
      for (let index2 = 0; index2 < Objeto.length; index2++) {
        console.debug("Yo tengo: ",Objeto[index2])
        console.debug(this.Info[index].opciones[index2])
        let opcionaux = this.Info[index].opciones[index2].idiomas.filter(x => x.idioma != this.Idioma )

        console.debug("Estas son las que guardas:",opcionaux)
        let NuevasOpciones = []
        NuevasOpciones.push(Objeto[index2])
        // eslint-disable-next-line vue/no-mutating-props
        this.Info[index].opciones[index2].idiomas = [...NuevasOpciones, ...opcionaux]
      }
      console.debug("Asi queda",this.Info[index].opciones)
        return
    },
  },
};
</script>

<style scoped>
.card-edition {
  min-width: 200px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>