<!-- @format -->

<template>
  <router-link
    class="card bg-light bg-gradient p-2"
    :to="{ path: RutaDestino }"
    @click.stop="Editar(registro)"
  >
    <div class="position-absolute end-2">
      <router-link
        v-if="registro.borrado"
        :to="{}"
        class="p-2 mt-4 text-secondary"
        data-effect="fadeOut"
        @click.stop="Reactivar(registro)"
        ><i class="fa fa-check"></i
      ></router-link>
      <router-link
        v-else
        :to="{}"
        class="p-2 mt-4 text-secondary"
        data-effect="fadeOut"
        @click.stop="Borrar(registro)"
        ><i class="fa fa-times"></i
      ></router-link>
    </div>
    <div class="d-flex justify-content-around">
      <div class="col-3 pt-2 text-center">
        <img
          class="img-fluid rounded-circle"
          :src="FotoURL"
          alt="profile card"
        />
        <h5 class="text-muted">{{ LinkID }}</h5>
      </div>
      <div class="col-9 p-2 text-center">
        <h4 class="mb-0 pb-0 me-2 text-break text-truncate">
          {{ Etiqueta }}
        </h4>
        <h5 class="mb-0 pb-0 text-muted text-truncate">{{ Descripcion }}</h5>
        <hr class="mx-4 text-muted" />
        <div class="d-flex justify-content-center">
          <router-link to="/personal/calificaciones" class="text-primary icons">
            <b>{{ Calificacion }}</b>
            <i class="fa fa-star ms-2"></i>
          </router-link>
          <router-link to="/personal/evaluaciones" class="ms-4 text-primary icons">
            <b>{{ Evaluacion }}</b>
            <i class="fa fa-check-double ms-2"></i>
          </router-link>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Servicio from "@/api-services/colaboradores.yacalificame.service";

/* import ModalEditarColaborador from "./Modals/ModalEditarColaborador.vue"; */

export default {
  name: "PersonalCardRegistro",
  components: {
    /* ModalEditarColaborador, */
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  emits: ["editar", "borrar", "reactivar"],
  computed: {
    RutaDestino() {
      return `${this.$route.path}/${this.registro.id}`;
    },
    Puesto() {
      if (this.registro.puesto == null) return "";
      return this.registro.puesto.etiqueta;
    },
    Calificacion() {
      var Calificaciones = this.registro.calificacion;
      if (Calificaciones == null || Calificaciones == "") return "-";
      return `${Calificaciones}`;
    },
    Evaluacion() {
      var Encuestas = this.registro.encuestas;
      if (Encuestas == null || Encuestas == "") return "-";
      return `${Encuestas}`;

      /* if (this.registro.encuestas == "") return "-";
      return this.registro.encuestas; */
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.registro);
      return img;
    },
    LinkID() {
      if (this.registro == null) return "-";
      if (this.registro.linkID == "") return "-";
      return this.registro.linkID;
    },
    Etiqueta() {
      if (this.registro == null) return "-";
      if (this.registro.etiqueta == "") return "-";
      return this.registro.etiqueta;
    },
    Descripcion() {
      var Puesto = this.registro.puesto;
      if (Puesto == null || Puesto == "") return "-";

      return `${Puesto}`;
    },
  },
  mounted() {
    /* this.Actualiza(); */
  },
  methods: {
    Editar(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
      console.debug(registro.encuestaID);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilos específicos del componente */

.profile-card {
  width: 100%;
  /* height: 20rem; */
  min-height: 200px;
  margin: auto;
  background: #f2f2f2;
  border-radius: 12px;
  max-width: 700px;
  position: relative;

  &.active {
    .profile-card__cnt {
      filter: blur(6px);
    }

    .profile-card-message,
    .profile-card__overlay {
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0.1s;
    }

    .profile-card-form {
      transform: none;
      transition-delay: 0.1s;
    }
  }

  &__img {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-50%, -50%); /* Ajusta para centrar completamente */

    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    z-index: 4;
    left: 50%; /* Centra el elemento horizontalmente */
    @media screen and (max-width: 576px) {
      width: 120px;
      height: 120px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

h4.parrafo_azul {
  color: #343a40;
}

.text-primary .icons:hover{
  color: #343A40;
}

</style>
