
<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="col-1">
          <img
            class="img-girada"
            src="@/assets/img/logosya/Gafetes-21.png"
            alt="No imagen gafetes"
          />
        </div>
        <div class="col-3 text-center">
          <i class="fas fa-check-circle fa-5x text-primary"></i>
          <h3 class="mt-2 text-primary"><b>¡Tu solicitud fue enviada!</b></h3>
          <p>
            En breve nos comunicaremos contigo, para continuar con
            <span class="text-primary">el pago de tus credenciales</span>
          </p>
          <button class="btn btn-lg btn-success" type="button" @click="VerListado">
            Ir a listado del personal
          </button>
        </div>
      </div>
      <!-- <div class="mt-4 button-row d-flex col-12">
        <argon-button
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Prev"
          @click="emit('prevStep')"
          >Prev</argon-button
        >
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="emit('nextStep')"
          >Next</argon-button
        >
      </div> -->
    </div>
  </div>
</template>
<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router';
const emit = defineEmits(["nextStep", "prevStep"]);
const router = useRouter()

function VerListado() {
  router.push("/personal")
}
</script>

<style scoped>
.img-girada {
  transform: rotate(30deg);
  width: 13rem;
  height: auto;
}
</style>