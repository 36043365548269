import moment from "moment";
moment.locale("es-mx");

///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al 2022-11-05
export function IsoDate2Fecha(IsoDate) {
  if (IsoDate == null || IsoDate == "") return "";
  return IsoDate.substring(0, 10);
}
export function IsoDate2SinOffset(IsoDate) {
  return IsoDate.substring(0, 19);
}

/**
 * Agrega o resta minutos a una fecha manteniedo el timezone
 * @param {*} IsoDate Convierte una fecha del formato 2022-11-05T07:32:35-07:00
 * @param {*} Minutos Cantidad de minutos
 */
export function IsoDateAddMinutos(IsoDate, Minutos) {
  var Desde = IsoDate.length - 6;
  var timezone = IsoDate.substring(Desde, Desde + 6);

  // Fecha con timezone guardada en texto
  const fechaTexto = IsoDate.substring(0, Desde);
  //console.debug(fechaTexto);
  // Crear objeto de fecha a partir del texto
  const fecha = new Date(fechaTexto);

  // Obtener valor numérico del tiempo en milisegundos
  let tiempo = fecha.getTime();

  // Agregar 60,000 milisegundos (un minuto)
  tiempo += Minutos * 60000;

  // Crear nuevo objeto de fecha con el valor de tiempo actualizado
  const nuevaFecha = new Date(tiempo);

  // Formatear la fecha en el formato deseado
  const nuevaFechaTexto =
    nuevaFecha.getFullYear() +
    "-" +
    ("0" + (nuevaFecha.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + nuevaFecha.getDate()).slice(-2) +
    "T" +
    ("0" + nuevaFecha.getHours()).slice(-2) +
    ":" +
    ("0" + nuevaFecha.getMinutes()).slice(-2) +
    ":" +
    ("0" + nuevaFecha.getSeconds()).slice(-2) +
    timezone;
  return nuevaFechaTexto;
}
///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al 07:32
export function IsoDate2HorasMinutos(IsoDate) {
  if (
    IsoDate == null ||
    IsoDate == "" ||
    IsoDate == "0001-01-01T00:00:00+00:00" ||
    IsoDate == "0001-01-01T00:00:00"
  )
    return "";
  return IsoDate.substring(11, 16);
}

///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al 2022-11-05 07:32
export function IsoDate2FechaHorasMinutos(IsoDate) {
  return `${IsoDate2Fecha(IsoDate)} ${IsoDate2HorasMinutos(IsoDate)}`;
}
///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al Fecha.format("ddd, DD/MMM/YY, h:mm:ss a");
export function IsoDate2sFechaHoraAmplia(IsoDate) {
  return sFechaHora2moment(IsoDate).format("ddd, DD/MMM/YY, h:mm:ss a");
}
///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al Fecha.format("ddd, DD/MMM/YY");
export function IsoDate2sFechaAmplia(IsoDate) {
  return sFechaHora2moment(IsoDate).format("ddd, DD/MMM/YY");
}
///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al Fecha.format("ddd, DD/MMM/YY") quitando el UTC;
export function Date2sFechaAmplia(Date) {
  return sFechaHora2moment(IsoDate2Fecha(Date)).format("ddd, DD/MMM/YY");
}

///Convierte una fecha del formato 2022-11-05T07:32:35-07:00 a moment
export function sFechaHora2moment(FechaHora) {
  if (FechaHora && FechaHora != "0001-01-01T00:00:00+00:00") {
    var Fecha = moment(FechaHora);
    return Fecha;
  }
  return moment();
}
/**
 * Convierte una fecha del formato 2022-11-05T07:32:35-07:00 al objeto moment
 * @param {string ej: 2022-11-05T07:32:35-07:0} FechaHora
 * @returns
 */
export function IsoDate2moment(FechaHora) {
  return sFechaHora2moment(FechaHora);
}
/**
 * Obtiene la fecha con formato ddd, DD/MMM/YY
 * @param {moment} FechaHora
 * @returns
 */
export function moment2FechaAbrDia(FechaHora) {
  return FechaHora.format("ddd, DD/MMM/YY");
}
/**
 * Obtiene el día en úmero
 * @param {*} FechaHora
 * @returns
 */
export function Dia(FechaHora) {
  return sFechaHora2moment(FechaHora).date();
}
export function DiaNombre(FechaHora) {
  return sFechaHora2moment(FechaHora).format("ddd");
}
///sustituye las horas, minutos y segundos del siguiente formato una fecha del formato 2022-11-05T07:32:35-07:00
export function IsoDateSustituyeHorasMinutos(FechaHora, HorasMinutosSegundos) {
  if (HorasMinutosSegundos == null) HorasMinutosSegundos = "00:00:00";
  if (HorasMinutosSegundos.length == 5)
    HorasMinutosSegundos = `${HorasMinutosSegundos}:00`;
  return `${FechaHora.substring(
    0,
    11
  )}${HorasMinutosSegundos}${FechaHora.substring(19)}`;
}
///sustituye las horas, minutos y segundos del siguiente formato una fecha del formato 2022-11-05T07:32:35-07:00
export function IsoDateSustituyeFecha(FechaHora, Fecha) {
  return `${Fecha}${FechaHora.substring(10)}`;
}

/**
 * Convierte un IsoDate a la hora en formato HH:mm, es
 * Es decir horas de 24 : minutos
 * Convertirá al time zone local si se requiere usando una
 * configuración
 * @param {*} FechaHora
 * @returns
 */
export function IsoDate2HoraHHmmTZ(FechaHora) {
  return IsoDate2HorasMinutos(FechaHora);
}

/**
 * OMITE el time Zone
 * Convierte una fecha del formato 2022-11-05T07:32:35-07:00
 * al Fecha.format("ddd, DD/MMM/YY, h:mm:ss a");
 * @param {*} IsoDate
 * @returns
 */
export function IsoDate2FechaHoraLargaTZ(IsoDate) {
  if (
    IsoDate == null ||
    IsoDate == "" ||
    IsoDate == "0001-01-01T00:00:00+00:00" ||
    IsoDate == "0001-01-01T00:00:00"
  )
    return "";

  return IsoDate2sFechaHoraAmplia(IsoDate.substring(0, 19));
}


export function formatDateToCustomString(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const timeString = date.toLocaleTimeString("en-US", options).replace(/\s/g, "").toUpperCase();

  let formattedDate = "";

  // Check if the date is today
  const isToday = date.toDateString() === today.toDateString();

  if (!isToday) {
    const day = date.getDate();
    const month = date.toLocaleString("es-MX", { month: "long" });
    formattedDate += `${day}/${month.charAt(0).toUpperCase() + month.slice(1)}`;
  }

  // Check if the year is the same as the current year
  const isSameYear = date.getFullYear() === today.getFullYear();

  if (!isSameYear) {
    const year = date.getFullYear();
    formattedDate += `/${year}`;
  }

  return `${timeString.toLowerCase()} ${formattedDate}`;
}