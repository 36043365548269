/** @format */

import Base from "@/api-services/base";

import YaCalificaMe from "@/api-services/eTickets.service.js";
const BaseURL = `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Impresiones/Gafete`;

export default {
  post(Vista,Parametros, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}`);
    return Base.post(
      Vista,
     `${URL}?SobreNombre=${Parametros.SobreNombre}&ColaboradorID=${Parametros.ColaboradorID}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  postImprime(Vista,ID,OnCorrecto,OnErroneo,OnFinalizado){
    let URL = Base.ObtenURLSuscripcion(`${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Impresiones/${ID}`)
    return Base.post(Vista,URL,{},OnCorrecto,OnErroneo,OnFinalizado)
  },
 
  //   Abajo es como debe de enviarse el params
//   post(Vista,Parametros, OnCorrecto, OnErroneo, OnFinalizado) {
//     var URL = Base.ObtenURLSuscripcion(`${BaseURL}`);
//     return Base.post(
//       Vista,
//      URL,
//       {},
//       {params:Parametros},
//       OnCorrecto,
//       OnErroneo,
//       OnFinalizado
//     );
//   },
};
