<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import MazSpinner from "maz-ui/components/MazSpinner";
import Chart from "chart.js/auto";
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";

const props = defineProps({
  id: {
    type: String,
    default: "bubble-chart",
  },
  title: {
    type: String,
    default: "",
  },
  height: {
    type: [String, Number],
    default: "150",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
});


const emit = defineEmits(["Expandir"])

const isExpanded = ref(false)

const inforetorno = ref(null)

const cargando = ref(true)

const Expandir = () => {
  isExpanded.value = !isExpanded.value
  if (isExpanded.value == false) {
    emit("Expandir", "Todas")
    return
  }
  emit("Expandir", "Indice")
}

const CrearGrafica = () => {
  let rango = null
  let ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
  let datos = []
  for (let index = 0; index < inforetorno.value.datasets.length; index++) {
    for (let index2 = 0; index2 < inforetorno.value.datasets[index].data.length; index2++) {
      inforetorno.value.datasets[index].data[index2].r = inforetorno.value.datasets[index].data[index2].r / 10
    }

    datos.push({
      label: inforetorno.value.datasets[index].label,
      data: inforetorno.value.datasets[index].data,
      backgroundColor: "#182B4D ",
      borderDash: [5, 5],
    })

    rango = inforetorno.value.datasets[index].rango.map((x) => {
      return x + " veces"
    })
  }

  new Chart(ctx, {
    type: "bubble",
    data: {
      labels: ["Clientes"],
      datasets: datos
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [4, 4],
            min: 0,
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#b2b9bf",
            font: {
              size: 10,
              family: "Open Sans",
              style: "normal",
              lineHeight: 2,
            },
          },
          type: "category", // Tipo de escala para el eje y
          position: "left", // Posición del eje y
          labels: rango, // Etiquetas personalizadas para los meses
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            min: 0,
          },
          ticks: {
            display: true,
            color: "#b2b9bf",
            padding: 9,
            font: {
              size: 11,
              family: "Open Sans",
              style: "normal",
              lineHeight: 2,
            },
          },
          type: "category",
          position: "left",
          labels: inforetorno.value.labels,
        },
      },
    },
  });



}

onBeforeMount(() => {
  ServiciosDashboard.getsRetornos(null, {}, (response) => {
    console.debug("Dahsboard", response);
    let respuesta = response.data
    let titulos = Object.getOwnPropertyNames(respuesta)
    titulos.slice(0, 1)
    inforetorno.value = {
      labels: respuesta.ejeX,
      datasets: []
    }
    for (let index = 0; index < titulos.length; index++) {
      if (titulos[index] != "ejeX") {
        let rango = []
        for (let index2 = 0; index2 < respuesta[titulos[index]].length; index2++) {
          if (index2 == 0) {
            let valor = respuesta[titulos[index]]
            rango.push(valor[index2].y)
          }
          let valor2 = respuesta[titulos[index]]
          if (rango.indexOf(valor2[index2].y) == -1) {
            rango.push(valor2[index2].y)
          }
        }
        inforetorno.value.datasets.push({
          label: titulos[index],
          data: respuesta[titulos[index]],
          rango: rango,
        })
      }
    }
    cargando.value = false
  });
})

watch(cargando, (newValue, oldValue) => {
  if (newValue == false) {
    setTimeout(() => {
      CrearGrafica()
    }, 1000);

  }
})
</script>
<template>
  <div class="container-fluid">
    <div class="w-100 d-flex justify-content-between mt-3">
      <div class="" style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #21c997;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <i class="fa fa-file-alt" style="color: white; font-size: 1.5rem"></i>
      </div>
      <div class="">
        <h5>Indice de retorno de clientes</h5>
      </div>
      <div>
        <button class="btn rounded-circle mt-1" @click="Expandir">
          <i class="fa fa-expand" v-if="!isExpanded"></i>
          <i class="fa fa-compress" v-else></i>
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 mt-5">
    <div class="w-50 mx-auto my-auto d-flex justify-content-center">
      <MazSpinner v-if="cargando" :size="'48'" color="secondary">
      </MazSpinner>
    </div>
  </div>
  <div class="p-3 card-body">
    <div class="chart">
      <canvas :id="props.id" class="chart-canvas" :height="props.height"></canvas>
    </div>
  </div>
</template>
