<!-- @format -->

<template>
  <div class="card mx-2 p-3 mx-lg-4 p-lg-4">
    <form @submit.prevent="Guardar">
      <Cargando :cargando="cargando">
        <div class="row">
          <div v-if="mostrarEtiqueta" class="col-md-4 p-1">
            <div class="card p-3">
              <MazInput v-model="Datos.linkID" label="Identificador" color="secondary" class="mb-campo">
                <template #left-icon>
                  <i class="fas fa-key text-primary"></i>
                </template>
                <template #right-icon>
                  <router-link :to="{}" @click="Datos.linkID = ''">
                    <i style="color: #8392ab !important" class="fas fa-times p-2"></i>
                  </router-link>
                </template>
              </MazInput>
              <MazInput v-model="Datos.etiqueta" label="Etiqueta" color="secondary" required class="mb-campo">
                <template #left-icon>
                  <i class="fas fa-tag text-primary"></i>
                </template>
                <template #right-icon>
                  <router-link :to="{}" @click="Datos.etiqueta = ''">
                    <i style="color: #8392ab !important" class="fas fa-times p-2"></i>
                  </router-link>
                </template>
              </MazInput>
              <slot name="Etiqueta"></slot>
            </div>
          </div>
          <div v-if="mostrarComentarios" class="col-md-8 p-1">
            <div class="card p-3 h-100
          ">
              <MazTextarea style="height: 100%;" v-model="Datos.descripcion" label="Comentarios" color="secondary"
                class="mb-campo" />
              <slot name="Comentarios"></slot>
            </div>
          </div>
        </div>
        <slot name="Completo"></slot>
      </Cargando>
      <hr />
      <argon-alert v-if="MsgError" color="danger">
        <strong>Error:</strong> {{ MsgError }}
      </argon-alert>
      <div class="d-flex justify-content-between">
        <router-link class="mt-2" :to="{}" @click="Regresar"><i
            class="fa fa-chevron-left me-2"></i>Cancelar</router-link>
        <button class="btn btn-success mb-0" type="submit">Guardar</button>
      </div>
    </form>

  </div>
</template>

<script>
import ArgonAlert from "@/components/ArgonAlert.vue";
import Base from "@/api-services/base";
import Cargando from "@/Componentes/Cargando.vue";
// import ConfidencialCliente from "@/Vistas/Encuestas/Componentes/CardConfidencialCliente.vue";

export default {
  name: "ListadosEdicion",
  components: {
    Cargando,
    ArgonAlert,
    //ConfidencialCliente
  },
  props: {
    mostrarComentarios: {
      type: Boolean,
      default: true,
    },
    mostrarEtiqueta: {
      type: Boolean,
      default: true,
    },
    mostrarPreguntas: {
      type: Boolean,
      default: true,
    },
    mostrarEncargado: {
      type: Boolean,
      default: true,
    },
    mostrarTuLogotipo: {
      type: Boolean,
      default: true,
    },
    servicio: {
      type: Object,
      required: true,
      default: null,
    },
    campoNombre: {
      type: String,
      default: "",
    },
    //Contiene los datos del objeto a guardar, si es nulo, se usará this.Datos
    datosGuardar: {
      type: Object,
      required: true,
      default: null,
    },
  },
  emits: ["update:datos", "update:campoNombre"],
  data() {
    return {
      localCampoNombre: this.campoNombre,
      ID: "",
      Datos: {
        etiqueta: "",
        descripcion: "",
      },
      cargando: false,
      MsgError: "",
    };
  },

  computed: {
    Cancelar() {
      return Base.ObtenerRutaSinUltimoSegmento(this.$route.path);
    },
  },
  watch: {
    Datos: function (val) {
      this.$emit("update:datos", val);
    },
  },
  beforeMount() {
    if (this.$route.query.id != null || this.$route.params.id != null) {
      var ID = "";
      if (this.$route.query.id != null) ID = this.$route.query.id;
      if (this.$route.params.id != null) ID = this.$route.params.id;
      this.ID = ID;
      //this.Datos = Object.assign({}, this.value);
      this.CargarDatos();
    }
  },
  mounted() {

  },
  methods: {
    Regresar() {
      if (window.history.length > 1) this.$router.go(-1);
      else this.$router.push(this.Cancelar);
    },
    Validar() {
      return true;
    },
    Guardar() {
      if (!this.Validar()) return false;

      var Datos = this.Datos;
      Datos.sDatos = JSON.stringify(Datos.ReceptInfo)
      if (this.datosGuardar != null) Datos = this.datosGuardar;

      this.servicio.put(this, this.ID, Datos, (response) => {
        this.Regresar();
        //this.$router.go(-1);
        /*
          var Datos = response.data;
          console.debug(Datos);
          this.Datos = Datos;*/
      });
    },
    CargarDatos() {
      if (this.servicio == null) return;
      this.servicio.get(this, this.ID, (response) => {
        var Datos = response.data;
        console.debug(Datos);
        this.Datos = Datos;
        this.Datos.ReceptInfo = {
          Receptor: "",
          TelReceptor: "",
        }
        if (this.Datos.datos != null) {
          this.Datos.ReceptInfo.Receptor = this.Datos.datos.Receptor
          this.Datos.ReceptInfo.TelReceptor = this.Datos.datos.TelReceptor
        }
        console.debug("El modelo queda asi: ", this.Datos)
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
