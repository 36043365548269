<!-- @format -->

<template>
  <Modal
    v-if="MostrarModal"
    :show="MostrarModal"
    modal-classes="modal-dialog-centered"
    :type="type"
    @close="closeModal"
  >
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-title text-center"><i class="pe-2 fas fa-ticket-alt"></i>Nuevo Ticket</h5>
    </template>

    <template #body>
      <div class="p-3">
        <Cargando :cargando="cargando" :msg-error="msgError">
          <form @submit.prevent="Agregar">
            <div class="d-grid gap-1">
              <div>
                <SelectTopico v-model="Datos.topicoID" class="mb-campo" color="secondary" required />

                <SelectPersonal
                  v-if="!soloMios"
                  v-model="Datos.colaboradorID"
                  class="mb-campo"
                  color="secondary"
                  required
                />

                <MazTextarea v-model="Datos.comentario.comentario" label="Comentarios" class="mb-campo" />
              </div>
              <hr />
              <MazCheckbox v-if="mostrarIrAEdicion" v-model="IrAEdicion" size="sm" color="secondary">
                Editar al registrar
              </MazCheckbox>

              <button class="btn btn-success mb-2" type="submit">Registrar</button>
            </div>
          </form>
        </Cargando>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
import Cargando from "@/Componentes/Cargando.vue";
import SelectPersonal from "@/Vistas/Personal/Select.vue";
import SelectTopico from "@/Vistas/Topicos/Select.vue";
export default {
  components: {
    Modal,
    Cargando,
    SelectPersonal,
    SelectTopico,
  },
  props: {
    soloMios: {
      type: Boolean,
      default: false,
    },
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    //Muestra que esta cargando
    cargando: { type: Boolean, default: true },

    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", "update:ir-a-edicion"],

  data() {
    return {
      IrAEdicion: true,
      MostrarModal: this.mostrar,
      Datos: {
        etiqueta: "",
        descripcion: "",
        comentario: {
          comentario: "",
          archivos: [],
        },
      },
    };
  },

  computed: {
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },
    removeUrlParameters(url) {
      const urlObject = new URL(url);
      return urlObject.origin + urlObject.pathname;
    },
    Agregar() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }
      var URL = this.removeUrlParameters(window.location.href);
      this.Datos.comentario.UrlNotificacion = `${URL}/`;
      console.log("Agregar", JSON.stringify(this.Datos));
      this.$emit("agregar", this.Datos);
    },
  },
};
</script>
