<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Sobrenombre</th>
          <th>Fecha de solicitud</th>
          <th>Estado de gafete</th>
          <th>Fecha y hora de entrega</th>
          <th>Enviado a</th>
          <th>Recibió</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="registro in registros" :key="registro.id">

          <td>
            <!-- <p>ID GAFETE: {{ registro.gafeteID }}</p> -->
            <img class="rounded-circle" :src="registro.urlMiniatura
          ? registro.urlMiniatura
          : '/img/default-avatar.png'
          " alt="No hay foto" style="width: 50px; height: auto" />
            <span class="ms-2">{{ registro.nombre }}</span>
          </td>
          <td>
            <span v-if="registro.sobrenombre">{{ registro.sobrenombre }}</span>
            <!-- <span v-else>No existe sobrenombre para este colaborador</span> -->
            <span class="text-center" v-else>---</span>
          </td>

          <td>{{ formatoFechaSolicitado(registro.fechaSolicitado) }}</td>
          <td>
            <MazBadge :type="badgeType">{{ registro.eEstatusEnvio }}</MazBadge>
            <!-- <button class="btn btn-outline-success" @click="changeState">
              Cambiar Estado
            </button> -->
          </td>
          <td>{{ formatoFechaEntrega(registro.fechaEntrega) }}</td>
          <td>{{ registro.destinatario }}</td>
          <td>{{ registro.receptor }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MazBadge from "maz-ui/components/MazBadge";

import Servicio from "@/api-services/colaboradores.yacalificame.service";

export default {
  name: "Tabla",
  components: {
    MazBadge,
  },
  props: {
    datos: {
      type: Array,
      required: true,
    },
    registros: {
      type: Array,
      required: true,
    },
    listado: {
      type: Object,
      required: true,
    },
    initialState: {
      type: String,
      default: "sin-solicitar", // Estado inicial
    },
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
      currentState: this.initialState,
    };
  },
  emits: ["editar", "borrar", "reactivar"],
  computed: {
    Puesto() {
      if (this.registro.puesto == null) return "";
      return this.registro.puesto.etiqueta;
    },
    Calificacion() {
      var Calificaciones = this.registro.calificacion;
      if (Calificaciones == null || Calificaciones == "") return "-";
      return `${Calificaciones}`;
    },
    Evaluacion() {
      var Encuestas = this.registro.encuestas;
      if (Encuestas == null || Encuestas == "") return "-";
      return `${Encuestas}`;

      /* if (this.registro.encuestas == "") return "-";
      return this.registro.encuestas; */
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.registro);
      return img;
    },
    LinkID() {
      if (this.registro == null) return "-";
      if (this.registro.linkID == "") return "-";
      return this.registro.linkID;
    },
    Etiqueta() {
      if (this.registro == null) return "-";
      if (this.registro.etiqueta == "") return "-";
      return this.registro.etiqueta;
    },
    Descripcion() {
      var Puesto = this.registro.puesto;
      if (Puesto == null || Puesto == "") return "-";

      return `${Puesto}`;
    },
    badgeType() {
      // Mapear los estados a tipos de MazBadge
      const stateMap = {
        enviado: "success",
        entregado: "info",
        "solicitud-recibida": "warning",
        "sin-solicitar": "error",
        solicitar: "primary",
      };
      return stateMap[this.currentState] || "default";
    },
    badgeText() {
      // Puedes personalizar el texto del badge según el estado
      const textMap = {
        enviado: "Enviado",
        entregado: "Entregado",
        "solicitud-recibida": "Solicitud recibida",
        "sin-solicitar": "Sin solicitar",
        solicitar: "Solicitar",
      };
      return textMap[this.currentState] || "Desconocido";
    },
  },
  mounted() {
    /* this.Actualiza(); */
  },
  methods: {
    Editar(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
      console.debug(registro.encuestaID);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
    changeState() {
      // Método para cambiar el estado
      if (this.currentState === "enviado") {
        this.currentState = "entregado";
      } else if (this.currentState === "entregado") {
        this.currentState = "solicitud-recibida";
      } else if (this.currentState === "solicitud-recibida") {
        this.currentState = "sin-solicitar";
      } else if (this.currentState === "sin-solicitar") {
        this.currentState = "solicitar";
      } else {
        this.currentState = "enviado";
      }
    },
    formatoFechaSolicitado(fecha) {
      const date = new Date(fecha);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('es-ES', options);
    },
    formatoFechaEntrega(fecha) {
      return this.formatoFecha(fecha);
    },
    formatoFecha(value) {
      const date = new Date(value);
      const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
      return date.toLocaleString('es-MX', options);
    }
  },
};
</script>

<style lang="scss" scoped>
table.container {
  font-weight: 200;
  font-style: normal;
}

/* Estilos opcionales para ajustar el diseño según sea necesario */
.container {
  margin-top: 20px;
}
</style>