<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import MazSpinner from "maz-ui/components/MazSpinner";
import Chart from "chart.js/auto";
import ServiciosDashboard from "@/api-services/dashboardsgeneral.yacalificame.service";


const props = defineProps({
  id: {
    type: String,
    default: "chart-line",
  },
  title: {
    type: String,
    default: "Default Line Chart",
  },
  height: {
    type: String,
    default: "300",
  },
  /* width: {
    type: String,
    default: "550",
  }, */
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
});


const emit = defineEmits(["Expandir"])

const isExpanded = ref(false)

const infogenero = ref(null)

const cargando = ref(true)

const Expandir = () => {
  isExpanded.value = !isExpanded.value
  if (isExpanded.value == false) {
    emit("Expandir", "Todas")
    return
  }
  emit("Expandir", "Distribucion")
}

const CrearGrafica = () => {
  let chart = document.getElementById(props.id).getContext("2d");

  let gradientStroke2 = chart.createLinearGradient(0, 230, 0, 50);

  gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
  gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
  gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
  if (infogenero.value.datasets.length === 3) {
    new Chart(chart, {
      type: "line",
      data: {
        labels: infogenero.value.labels,
        datasets: [
          {
            label: infogenero.value.datasets[0].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#ffacd2",
            borderColor: "#ffacd2",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[0].data,
            maxBarThickness: 6,
          },
          {
            label: infogenero.value.datasets[1].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#0fcdef",
            borderColor: "#0fcdef",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[1].data,
            maxBarThickness: 6,
          },
          {
            label: infogenero.value.datasets[2].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#FFCB6A",
            borderColor: "#FFCB6A",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[2].data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
            min: 0,
            max: 1500,
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#9ca2b7",
              padding: 10,
            },
          },
        },
      },
    });
  } else if (infogenero.value.datasets.length === 2) {
    new Chart(chart, {
      type: "line",
      data: {
        labels: infogenero.value.labels,
        datasets: [
          {
            label: infogenero.value.datasets[0].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#cb0c9f",
            borderColor: "#cb0c9f",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[0].data,
            maxBarThickness: 6,
          },
          {
            label: infogenero.value.datasets[1].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#3A416F",
            borderColor: "#3A416F",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[1].data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#9ca2b7",
              padding: 10,
            },
          },
        },
      },
    });
  } else if (infogenero.value.datasets.length === 1) {
    new Chart(chart, {
      type: "line",
      data: {
        labels: infogenero.value.labels,
        datasets: [
          {
            label: infogenero.value.datasets[0].label,
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: "#cb0c9f",
            borderColor: "#cb0c9f",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: infogenero.value.datasets[0].data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#9ca2b7",
              padding: 10,
            },
          },
        },
      },
    });
  }
}
onBeforeMount(() => {
  ServiciosDashboard.getsGeneros(null, {}, (response) => {
    console.debug("Datos dashboard genero: ", response);
    let respuesta = response.data
    let titulos = Object.getOwnPropertyNames(respuesta)
    titulos.slice(0, 1)
    infogenero.value = {
      labels: respuesta.ejeX,
      datasets: []
    }
    for (let index = 0; index < titulos.length; index++) {
      if (titulos[index] != "ejeX") {
        infogenero.value.datasets.push({
          label: titulos[index],
          data: respuesta[titulos[index]],
        })
      }
    }
    cargando.value = false
  });
});

watch(cargando, (newValue, oldValue) => {
  if (newValue == false) {
    setTimeout(() => {
      CrearGrafica()
    }, 1000);

  }
})

</script>
<template>
  <div class="container">
    <div class="w-100 d-flex justify-content-between mt-3">
      <div class="" style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #21c997;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <i class="fa fa-venus-mars" style="color: white; font-size: 1.5rem"></i>
      </div>
      <div class="">
        <h5>Distribución Género de los clientes</h5>
      </div>
      <div>
        <button class="btn rounded-circle mt-1" @click="Expandir">
          <i class="fa fa-expand" v-if="!isExpanded"></i>
          <i class="fa fa-compress" v-else></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="p-3 pb-0 card-header">
          <div class="d-flex justify-content-center align-items-center">
            <template v-if="infoGenero && infoGenero.generos.length">
              <span v-for="(genero, index) in infoGenero.generos" :key="index" class="badge badge-md badge-dot me-4">
                <i :style="{ backgroundColor: ['#ffacd2', '#0fcdef', '#FFCB6A'][index % 3] }"></i>
                <span class="text-xs text-dark">{{ genero }}</span>
              </span>
            </template>
            <template v-else>
              <p>No hay géneros por el momento</p>
            </template>
          </div>
        </div>
        <div class="w-100">
          <div class="w-50 mx-auto my-auto d-flex justify-content-center">
            <MazSpinner v-if="cargando" :size="'48'" color="secondary">
            </MazSpinner>
          </div>
        </div>
        <div class="p-3 card-body">
          <div class="chart">
            <canvas :id="props.id" class="chart-canvas" :height="props.height"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
