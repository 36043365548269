/** @format */

import Base from "@/api-services/base";

import eTickets from "@/api-services/eTickets.service.js";
const BaseURL = `${eTickets.URL}/Suscripciones/{{SuscripcionID}}/Topicos`;

export default {
  GetsResponse: null,
  Listado: [],
  FotoURL(Colaborador) {
    var R = "/img/default-avatar.png";
    // console.debug(Colaborador);
    if (
      Colaborador != undefined &&
      Colaborador != null &&
      Colaborador.urlMiniatura != undefined &&
      Colaborador.urlMiniatura != null &&
      Colaborador.urlMiniatura != ""
    ) {
      R = Colaborador.urlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    if (
      Colaborador != undefined &&
      Colaborador != null &&
      Colaborador.UrlMiniatura != undefined &&
      Colaborador.UrlMiniatura != null &&
      Colaborador.UrlMiniatura != ""
    ) {
      R = Colaborador.UrlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    //console.debug(R);
    return R;
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsEnriquecido(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    console.log("GetsEnriquecido");
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Enriquecido`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null) OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      { PaginaLen: 1000 },
      (response) => {
        this.GetsResponse = response;
        this.Listado = response.data.listado;
        if (OnCorrecto != undefined) OnCorrecto(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  putCampos(Vista, Campos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Campos`);
    return Base.put(
      Vista,
      URL,
      Campos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getAvanzado(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}/Avanzado`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //quita lo borrado
  patch(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnErroneo, OnFinalizado);
    //return Base.patchBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
};
