<!-- @format -->

<template>
  <Edicion :mostrar-comentarios="false" :mostrar-etiqueta="false" :mostrarEncargado="false" :mostrarTuLogotipo="false"
    :mostrar-preguntas="false" :servicio="Servicio" :datosGuardar="Colaborador"
    @update:datos="(value) => (Datos = value)">
    <template #Completo>

      <div class="d-flex justify-content-end">
        <i class="d-block d-sm-none fa-2x fas fa-id-card" style="color: #2dcec6"></i>
      </div>
      <hr class="d-block d-sm-none" />
      <CardPerfilMovil class="mx-3 mt-0 mb-2 d-block d-sm-none" :urlMiniatura="Colaborador.urlMiniatura"
        :linkID="Colaborador.linkID"
        :nombreCompleto="`${Colaborador.nombre} ${Colaborador.apellidoP} ${Colaborador.apellidoM}`"
        :correo="Colaborador.correo" :telMovilPersonal="Colaborador.telMovilPersonal" />
      <!--Componentes ligar a su dato correcto-->
      <MenuEdicion class="mx-3 mt-0 mb-2" />
      <Personales id="personal" :linkID="Colaborador.linkID" :nombre="Colaborador.nombre"
        :apellidoP="Colaborador.apellidoP" :apellidoM="Colaborador.apellidoM" :genero="Colaborador.genero"
        :nacionalidad="Colaborador.nacionalidad" :curp="Colaborador.curp" :fechaNacimiento="Colaborador.fechaNacimiento"
        :urlMiniatura="Colaborador.urlMiniatura" class="m-3" @update:linkID="(value) => (Colaborador.linkID = value)"
        @update:nombre="(value) => (Colaborador.nombre = value)"
        @update:apellidoP="(value) => (Colaborador.apellidoP = value)"
        @update:apellidoM="(value) => (Colaborador.apellidoM = value)"
        @update:genero="(value) => (Colaborador.genero = value)"
        @update:nacionalidad="(value) => (Colaborador.nacionalidad = value)"
        @update:curp="(value) => (Colaborador.curp = value)" @update:fechaNacimiento="(value) => (Colaborador.fechaNacimiento = value)
    " @update:urlMiniatura="(value) => (Colaborador.urlMiniatura = value)" />
      <DatosAcceso id="datos-acceso" :correo="Colaborador.correo" :telMovilPersonal="Colaborador.telMovilPersonal"
        :perfil="Colaborador.perfil" class="m-3" @update:correo="(value) => (Colaborador.correo = value)"
        @update:telMovilPersonal="(value) => (Colaborador.telMovilPersonal = value)
    " @update:perfil="(value) => (Colaborador.perfil = value)" />
      <Domicilio id="domicilio" :dDireccion="Colaborador.dDireccion"
        @update:dDireccion="(value) => (Colaborador.dDireccion = value)" class="m-3" />
      <Laborales id="laborales" :fechaIngreso="Colaborador.fechaIngreso" :puestoID="Colaborador.puestoID"
        :departamentoID="Colaborador.departamentoID" :ubicacionID="Colaborador.ubicacionID" class="m-3"
        @update:fechaIngreso="(value) => (Colaborador.fechaIngreso = value)"
        @update:puestoID="(value) => (Colaborador.puestoID = value)"
        @update:departamentoID="(value) => (Colaborador.departamentoID = value)"
        @update:ubicacionID="(value) => (Colaborador.ubicacionID = value)" />

      <DatosGafete id="identificacion" :etiqueta="Colaborador.etiqueta" :sobreNombre="Colaborador.sobreNombre"
        :encuestaID="Colaborador.encuestaID" :formulariosCompleto="ValidarFormularios" class="m-3"
        @update:etiqueta="(value) => (Colaborador.etiqueta = value)"
        @update:sobreNombre="(value) => (Colaborador.sobreNombre = value)"
        @update:encuestaID="(value) => On('encuestaID', value)" />
    </template>
  </Edicion>
</template>

<script>
import CardPerfilMovil from "./Componentes/CardPerfilMovil.vue";
import DatosAcceso from "./Componentes/DatosAcceso.vue";
import DatosGafete from "./Componentes/DatosGafete.vue";
import Domicilio from "./Componentes/Domicilio.vue";
import Edicion from "@/Componentes/Listados/Edicion.vue";
import Personales from "./Componentes/Personales.vue";
import Laborales from "./Componentes/Laborales.vue";
import Servicio from "@/api-services/colaboradores.yacalificame.service";

import MenuEdicion from "./Componentes/MenuEdicion.vue";

export default {
  name: "PersonalEdicion",
  components: {
    CardPerfilMovil,
    DatosAcceso,
    DatosGafete,
    Domicilio,
    Edicion,
    Personales,
    Laborales,
    MenuEdicion,
  },
  data() {
    return {
      Datos: {},
    };
  },
  computed: {
    Colaborador() {
      if (this.Datos.colaborador == null) return {};
      return this.Datos.colaborador;
    },
    Servicio() {
      return Servicio;
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.localUrlMiniatura);
      return img;
    },
    ValidarFormularios() {
      return this.Colaborador.nombre && this.Colaborador.apellidoP && this.Colaborador.apellidoM &&
        this.Colaborador.genero && this.Colaborador.nacionalidad && this.Colaborador.curp &&
        this.Colaborador.fechaNacimiento && this.Colaborador.correo && this.Colaborador.telMovilPersonal &&
        this.Colaborador.perfil && this.Colaborador.dDireccion && this.Colaborador.fechaIngreso &&
        this.Colaborador.puestoID && this.Colaborador.departamentoID && this.Colaborador.ubicacionID &&
        this.Colaborador.etiqueta && this.Colaborador.sobreNombre && this.Colaborador.encuestaID;
    }

  },

  methods: {
    On(Propiedad, Valor) {
      console.debug(Propiedad, Valor);
      this.Colaborador[Propiedad] = Valor;
    }
  },
};
</script>

<style lang="scss" scoped></style>
