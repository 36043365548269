<!-- @format -->

<template>
  <Modal
    v-if="MostrarModal"
    :show="MostrarModal"
    modal-classes="modal-dialog-centered"
    :type="type"
    @close="closeModal"
  >
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-title text-center"><i class="pe-2 fas fa-ticket-alt"></i>Reportes de tickets</h5>
    </template>

    <template #body>
      <div class="p-3">
        <Cargando :cargando="cargando" :msg-error="msgError">
          <form @submit.prevent="Agregar">
            <div class="d-grid gap-1">
              <div></div>
              <MazPicker
                v-model="Periodo"
                type="text"
                label="Periodo"
                dateFormat="YYYY-MM-DD"
                :inputDateStyle="{dateStyle:'medium'}"
                :noShortcuts="true"
                format="YYYY-MM-DD"
                :color="HayError?'danger':'secondary'"
              >
                <div class="flex items-center" style="width: 100%; gap: 1rem; margin-left: 2rem"></div>
              </MazPicker>
              
              
              <div class="d-flex justify-content-left mt-3">
                <span>Reporte:</span>
                <MazRadio v-model="Datos.Excel" value="XEstadosTickets" color="secondary"> X Estados Tickets </MazRadio>
                <MazRadio v-model="Datos.Excel" value="Detallado" color="secondary"> Detallado </MazRadio>
              </div>
              <hr />

              <button class="btn btn-success mb-2" type="submit">Descargar</button>
            </div>
          </form>
        </Cargando>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
import Cargando from "@/Componentes/Cargando.vue";
import MazRadio from "maz-ui/components/MazRadio";

export default {
  components: {
    Modal,
    Cargando,
    MazRadio,
  },
  props: {
    soloMios: {
      type: Boolean,
      default: false,
    },
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    //Muestra que esta cargando
    cargando: { type: Boolean, default: true },

    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", "update:ir-a-edicion"],

  data() {
    return {
      IrAEdicion: true,
      MostrarModal: this.mostrar,
      Periodo: {
        start: "",
        end: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        Excel: "XEstadosTickets",
        comentario: {
          comentario: "",
          archivos: [],
        },
      },
    };
  },

  computed: {
    HayError()
    {
      if(this.Periodo == null)
        return true;
      if(this.Periodo.start == null || this.Periodo.start == "")
        return true;
      if(this.Periodo.end == null || this.Periodo.end == "")
        return true;
      return false;
    },
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },
    removeUrlParameters(url) {
      const urlObject = new URL(url);
      return urlObject.origin + urlObject.pathname;
    },
    Agregar() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }

      if(this.HayError)
      {
        return;
      }
      this.Datos.Desde = this.Periodo.start;
      this.Datos.Hasta = `${ this.Periodo.end}T23:59:59`;
      console.log("Excel", JSON.stringify(this.Datos));
      this.$emit("excel", this.Datos);
    },
  },
};
</script>
