<!-- @format -->

<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-0 px-md-2 py-0 container-fluid">
      <router-link :to="{}" @click="OnHome">
        <i class="fas fa-home p-2 text-white"></i>
      </router-link>
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"></div>

        <ul class="navbar-nav justify-content-end">
          <li class="nav-item">
            <NavbarDropDown :url-foto-miniatura="urlFotoMiniatura" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Servicio from "@/api-services/eTickets.tickets.service";
import { mapState, mapMutations, mapActions } from "vuex";
import Breadcrumbs from "../Breadcrumbs.vue";
import NavbarDropDown from "./NavbarDropDown.vue";
import { inject } from "vue";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,

    NavbarDropDown,
    /*ArgonAvatar,*/
  },
  setup() {
    // Injectar SuscripcionesUsuarios en el componente
    const SuscripcionesUsuarios = inject("SuscripcionesUsuarios");
    const Usuarios = inject("Usuarios");
    // Ahora SuscripcionesUsuarios está disponible para usar en la plantilla
    return {
      SuscripcionesUsuarios,
      Usuarios,
    };
  },
  data() {
    return {
      showMenu: false,
      PerfilLocal: {},
    };
  },

  computed: {
    urlFotoMiniatura() {
      return this.Usuarios.FotoUsuarioURL(this.PerfilLocal == null ? null : this.PerfilLocal.usuario);
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
  },
  mounted() {
    this.Usuarios.getPerfilLocal((PerfilLocal) => {
      this.PerfilLocal = PerfilLocal;
      console.log("PerfilLocal", this.PerfilLocal);
    });
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    OnHome() {
      var Home = "/tickets";
      var NivelesAccesos = Servicio.NivelesAccesosGet();

      console.log("NivelesAccesos", NivelesAccesos);
      if (NivelesAccesos != null && NivelesAccesos.esAdministrador) Home = "/tickets/operador";
      this.$router.push(Home);
    },
    capitalizeFirstLetter(string) {
      console.log("string", string);
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
  },
};
</script>
<style scoped></style>
