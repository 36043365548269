<template>
  <div class="card bg-success p-3">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-6">
        <div class="position-relative d-inline-block">
          <img
            :src="
              localUrlMiniatura ? localUrlMiniatura : '/img/default-avatar.png'
            "
            alt="No hay foto"
            style="width: 100%; height: 100%;"
            class="profile-img img-fluid rounded-circle"
          />
          <router-link
            class="position-absolute bg-white edit-btn m-0 rounded-circle bottom-0 end-0"
            to="/editafoto"
          >
            <i
              style="color: #2dcec6; background-color: #ffffff"
              class="fas fa-pencil-alt"
            ></i>
          </router-link>
        </div>
      </div>
      <div class="col-6">
        <p
        style="font-size: 12px"
          class="text-center text-white"
        >
          ID: {{ localLinkID }}
        </p>
        <p class="text-center text-white text-bold">
          {{ nombreCompleto }}
        </p>
        <p style="font-size: 12px" class="text-center text-white">
          Encuesta {datos.encuesta}
        </p>
        <p style="font-size: 12px" class="text-center text-white">
          {{ localUbicacion }}
        </p>
        <p style="font-size: 12px" class="text-center text-white">
          {{ localMovilPersonal }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Base from "@/api-services/base";
import Servicio from "@/api-services/colaboradores.yacalificame.service";


export default {
  components: {},
  props: {
    linkID: {
      type: String,
      default: "",
    },
    nombre: {
      type: String,
      default: "",
    },
    apellidoP: {
      type: String,
      default: "",
    },
    apellidoM: {
      type: String,
      default: "",
    },
    genero: {
      type: String,
      default: "",
    },
    nacionalidad: {
      type: String,
      default: "",
    },

    curp: {
      type: String,
      default: "",
    },
    fechaNacimiento: {
      type: String,
      default: "",
    },
    urlMiniatura: {
      type: String,
      default: "",
    },
    correo: {
      type: String,
      default: "",
    },
    telMovilPersonal: {
      type: String,
      default: "",
    },
    nombreCompleto: {
      type: String,
      default: "",
    },
  },

  emits: [
    "update:linkID",
    "update:nombre",
    "update:apellidoP",
    "update:apellidoM",
    "update:genero",
    "update:nacionalidad",
    "update:curp",
    "update:fechaNacimiento",
    "update:urlMiniatura",
    "update:correo",
    "update:telMovilPersonal",
    "editar",
  ],
  setup() {},
  data() {
    return {
      localLinkID: this.linkID,
      localNombre: this.nombre,
      localApellidoP: this.apellidoP,
      localApellidoM: this.apellidoM,
      localGenero: this.genero,
      localNacionalidad: this.nacionalidad,
      localCurp: this.curp,
      localFechaNacimiento: this.fechaNacimiento,
      localUrlMiniatura: this.urlMiniatura,
      localEmailPersonal: this.correo,
      localMovilPersonal: this.telMovilPersonal,

      MostrarNuevo: false,
      selectedItem: null,
      cargando: false,

      inputValue: "",
      Erroneo: "",
      Datos: {
        nombre: "",
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
        /* FotoURL: "", */
      },
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {},
  computed: {
    error() {
      if (this.required)
        if (this.selectedItem == "Desconocido" || this.value == null)
          return true;
      return false;
    },
    Config() {
      {
        return {
          labelKey: "label",
          searchKey: "label",
          valueKey: "value",
        };
      }
    },
    MazPhoneNumber() {
      return Base.MazPhoneNumber;
    },
    EstiloCampo() {
      return "col-lg-4 col-12 col-sm-12 col-md-12 px-2 mb-campo";
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.localUrlMiniatura);
      return img;
    },
    RutaDestino() {
      return `${this.$route.path}/${this.Datos.id}`;
    },
  },

  watch: {
    value(valor) {
      this.selectedItem = valor;
      console.debug(valor);
    },
    selectedItem(valor) {
      //this.$emit('update:value', valor);
      this.$emit("input", valor);
      //  this.$set(this, 'value', valor);
      // this.value = valor;
    },
  },
  methods: {
    EditarFoto(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
    },
  },
  /* mounted() {
    if (this.value != null) this.selectedItem = this.value;
    this.Actualiza();
  }, */
};
</script>

<style lang="css" scoped>
.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-btn {
  position: absolute;
  bottom: -1rem;
  right: 2rem;
  bottom: 0;
  right: 0;
  border-radius: 60%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>