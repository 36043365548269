const isProd = process.env.NODE_ENV === "production";
import { sFechaPublicacion, sVersion } from "@/api-services/fechaPublicacion";
export default {
  IsProd() {
    // return true;
    return isProd;
  },
  get Version() {
    return sVersion;
  },
  get AuthorityURL() {
    var lURL = localStorage.AuthorityURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "go.eclock.com.mx":
      case "localhost":
      default:
        return "https://eClockAD.b2clogin.com/eClockAD.onmicrosoft.com/B2C_1_InicioSesion";
      case "td.eclock.com.mx":
      case "dolphin.eclock.com.mx":
      case "twindolphin.eclock.com.mx":
        return `https://eClockAD.b2clogin.com/eClockAD.onmicrosoft.com/B2C_1_TwinDolphin`;
      case "grupomasagua.eclock.com.mx":
      case "asistencia.grupomasagua.com":
        return `https://eClockAD.b2clogin.com/eClockAD.onmicrosoft.com/B2C_1_GrupoMasAgua`;
    }
  },
  get AuthURL() {
    var lURL = localStorage.AuthURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        return "http://localhost:8081";

      default:
        return "https://" + location.hostname;
      //return "http://localhost:8080/#/login?ad=1";
    }
  },
  get URL() {
    var lURL = localStorage.ApiURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        // return "http://localhost:8064/api";
        return "https://apiv6b.eclock.com.mx/api";
      case "App.eTickets":
        return "https://apiv6.eclock.com.mx/api";
      case "appb.eTickets":
        return "https://apiv6b.eclock.com.mx/api";

    }

    lURL = this.IsProd()
      ? "https://apiv6.eclock.com.mx/api"
      : "https://apiv6.eclock.com.mx/api"; // "https://debug.apiv6.eclock.com.mx/api")
    //  localStorage.URL = lURL;

    return lURL;
  },
  get SignalRURL() {
    var lURL = localStorage.SignalRURL;
    if (lURL) return lURL;
    lURL = "https://ServicesV6.eclock.com.mx/SignalRHub"; //((this.IsProd()) ? "https://apiv6.eclock.com.mx/api" : "https://debug.apiv6.eclock.com.mx/api")
    // localStorage.SignalRURL = lURL;

    return lURL;
  },
  //Obtiene la URL de redirección despues de logearse
  get LogInRedirect() {
    return localStorage.LogInRedirect;
  },
  set LogInRedirect(valor) {
    if (valor == null || valor == undefined)
      localStorage.removeItem("LogInRedirect");
    else localStorage.LogInRedirect = valor;
  },

  get EsSSO() {
    return localStorage.EsSSO;
  },
  set EsSSO(valor) {
    localStorage.EsSSO = valor;
  },
  GuardaValor(Nombre, Valor) {
    localStorage[Nombre] = Valor;
  },
  ObtenValorBool(Nombre, Predeterminado) {
    var Valor = localStorage[Nombre];
    if (Predeterminado == null || Predeterminado == undefined)
      Predeterminado = false;
    if (Valor == null || Valor == undefined) return Predeterminado;
    if (Valor == true || Valor == "true") return true;
    return false;
  },
  ObtenValor(Nombre, Predeterminado) {
    var Valor = localStorage[Nombre];
    if (Predeterminado == null || Predeterminado == undefined)
      Predeterminado = "";
    if (Valor == null || Valor == undefined) return Predeterminado;

    return Valor;
  },
  CabiarTema(Obscuro) {
    if (Obscuro == null || Obscuro == undefined) {
      if (localStorage.Obscuro == null || localStorage.Obscuro == undefined) {
        localStorage.Obscuro = true;
        Obscuro = true;
      } else Obscuro = localStorage.Obscuro;
    } else localStorage.Obscuro = Obscuro;
    let docClasses = document.body.classList;
    //console.debug(`Obscuro=${Obscuro}`);
    if (Obscuro == true || Obscuro == "true") {
      docClasses.remove("white-content");
      docClasses.add("maz-is-dark");
    } else {
      docClasses.add("white-content");
      docClasses.remove("maz-is-dark");
    }
  },
};
