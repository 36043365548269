/** @format */

const isProd = process.env.NODE_ENV === "production";
export default {
  IsProd() {
    // return true;
    return isProd;
  },

  get URL() {
    var lURL = localStorage.ApiURLeTickets;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        // return "http://localhost:8064/api";
        return "https://apib.eTickets.eClock.com.mx/api";
      case "app.etickets.eclock.com.mx":
        return "https://api.eTickets.eClock.com.mx/api";
      case "appb.etickets.eclock.com.mx":
        return "https://apib.eTickets.eClock.com.mx/api";
    }
    lURL = this.IsProd() ? "https://api.eTickets.eClock.com.mx/api" : "https://apib.eTickets.eClock.com.mx/api"; // "https://debug.apiv6.eclock.com.mx/api")
    //  localStorage.URL = lURL;

    return lURL;
  },
};
