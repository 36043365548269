<!-- @format -->

<template>
  <div>
    <div v-if="seleccionar" class="d-flex w-100">
      <div class="dropdown flex-grow-1">
        <button
          class="w-100 btn btn-primary dropdown-toggle mb-0 text-wrap"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ Etiqueta == null ? etiquetaNoSeleccionado : Etiqueta }}
        </button>
        <ul v-if="info" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li v-for="topico in Topicos" :key="topico.id">
            <a href="#" class="dropdown-item" :class="ObtenClase(topico)" @click="Seleccionar(topico)">
              {{ topico.etiqueta }}
            </a>
          </li>
        </ul>
      </div>
      <a v-if="!required" href="#" class="p-2 mx-2" @click="Seleccionar()">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div v-if="!seleccionar">
      <h4>{{ Etiqueta }}</h4>
      <h5 class="card-title text-center">Seleccionar Topico</h5>
      <div v-if="info" class="list-group">
        <a
          v-for="topico in Topicos"
          :key="topico.id"
          href="#"
          class="list-group-item list-group-item-action"
          :class="ObtenClase(topico)"
          @click="Seleccionar(topico)"
        >
          {{ topico.etiqueta }}
        </a>
      </div>
    </div>
    <!-- <i class="fas fa-users fa-3x"></i>-->
  </div>
</template>

<script>
import Service from "@/api-services/eTickets.topicos.service";
export default {
  name: "TopicosSelect",
  components: {},
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    seleccionar: {
      type: Boolean,
      required: false,
      default: true,
    },
    soloHijos: {
      type: Boolean,
      required: false,
      default: true,
    },
    etiquetaNoSeleccionado: {
      type: String,
      required: false,
      default: "Seleccione un topico",
    },
    label: {
      type: String,
      required: false,
      default: "Topico",
    },
    tipo: {
      type: String,
      required: false,
      default: "Topico",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      Etiqueta: null,
      MostrarModal: false,

      Seleccionado: this.modelValue,
      info: null,
    };
  },
  computed: {
    Topicos() {
      return this.info.listado.filter((x) => !x.borrado).sort((a, b) => a.path.localeCompare(b.path));
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ObtenClase(topico) {
      // Cuenta la cantidad de ocurrencias de '/'
      const depth = (topico.path.match(/\//g) || []).length;
      var EsPadre = this.soloHijos ? this.info.listado.some((x) => x.padreID == topico.id) : false;
      var EsSeleccionado = this.Seleccionado == topico.id;
      return `ps-${depth * 2} ${EsPadre ? "disabled" : ""} ${EsSeleccionado ? "active" : ""}`;
    },
    closeModal() {},
    Seleccionar(topico) {
      if (topico == null) {
        this.Seleccionado = null;
        this.Etiqueta = null;
        this.$emit("update:modelValue", null);
        return;
      }
      this.Seleccionado = topico.id;
      this.Etiqueta = topico.path;
      this.$emit("update:modelValue", topico.id);
    },
    ActualizaDatos() {
      Service.getsSelect(this, (response) => {
        this.info = response.data;
        if (this.Seleccionado != null)
          this.Etiqueta = this.info.listado.find((x) => x.id == this.Seleccionado)?.etiqueta;
      });
    },
  },
};
</script>
<style lang="scss">
.dropdown .btn.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.dropdown .dropdown-menu::before {
  content: "" !important;
  border: solid transparent;
  border-width: 0 .375rem .375rem .375rem;
  border-bottom-color: #fff !important;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block !important;
}
</style>
