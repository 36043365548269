<template>
  <table class="table">
    <thead>
      <tr>
        <th>
          <div class="card col-12 shadow bg-body-tertiary rounded">
            <div class="card-body d-flex justify-content-center">
              <div class="row">
                <div class="col mt-1">
                  <h4>Identificador</h4>
                </div>
                <div class="col">
                  <button :to="{}" @click="MostrarNuevo = !MostrarNuevo">
                    <i class="fas fa-plus-square text-primary fa-2sm"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </th>
        <th>
          <div class="card col-12">
            <div class="card-body d-flex justify-content-center">
              <div class="row">
                <div class="col mt-1">
                  <h4>Idioma</h4>
                </div>
                <div class="col">
                  <button :to="{}" @click="MostrarNuevo = !MostrarNuevo">
                    <i class="fas fa-plus-square text-primary fa-2sm"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </th>
        <th>
          <div class="card col-12">
            <div class="card-body d-flex justify-content-center">
              <div class="row">
                <div class="col">
                  <h4>Etiqueta</h4>
                </div>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(dato, index) in info" :key="index">
        
        <tr class="" v-for="(traduccion, idx) in dato.traducciones" :key="traduccion.id">
          <!-- <tr v-for="(Palabra, index) in Palabra" :key="index"></tr> -->
          <td class="text-center " v-if="idx === 0" :rowspan="dato.traducciones.length"
            @mouseover="MuestraBotonEditar = true" @mouseleave="MuestraBotonEditar = false">
            <div v-if="!dato.edicion" style="gap: 15px;" class="d-flex justify-content-center mt-5" >
              {{ dato.identificador }} <div style="cursor: pointer;" class=" text-primary" @click="Editarfila(dato)">
              <i class="fas fa-pencil-alt"></i>
            </div>
            </div>
            
            <div v-if="dato.edicion" class="mt-5">
              <CardEdicionIdioma :PalabraObj="dato" 
              Tipo="Identificador" @cancelar="CancelarEDetiqueta(dato)"
              @actualizar="(value) => actualizaED(value,index,idx,'Identificador')"/>
            
            </div>
          </td>
          <td class="text-center">{{ traduccion.idioma }}</td>
          <td style="gap: 15px;" class="text-center d-flex justify-content-center"> <div v-if="!traduccion.edicion">{{ traduccion.etiqueta }}</div>
            <div v-if="!traduccion.edicion" style="cursor: pointer;" class=" text-primary" @click="Editarfila(traduccion)">
              <i class="fas fa-pencil-alt"></i>
            </div>
            <div v-if="traduccion.edicion">
              <CardEdicionIdioma :PalabraObj="traduccion" 
              Tipo="Etiqueta" @cancelar="CancelarEDetiqueta(traduccion)"
              @actualizar="(value) => actualizaED(value,index,idx,'Etiqueta')"/>
            
            </div>
          </td>
        <td v-if="idx === 0 && EdicionActiva(dato)" :rowspan="dato.traducciones.length" style="border-top: none;" class="text-center">
         <button class="btn btn-success mt-5" @click="Guardar(dato)">Guardar</button>
        </td>
        </tr>
      </template>
    </tbody>
  </table>
  <!-- <p>Información de idiomas: {{ info }}</p> -->
  <ModalNuevo v-if="MostrarNuevo" :tipo="tipo" :tipo-icono="tipoIcono" :validar-datos="validarDatos"
    :cargando="cargando" :msg-error="msgError" :type="nuevoType" :mostrar-ir-a-edicion="mostrarIrAEdicion"
    @update:ir-a-edicion="(valor) => (IrAEdicion = valor)" @update:mostrar="(value) => (MostrarNuevo = value)"
    @update:datos="(datos) => ActualizaDatosNuevo(datos)" @agregar="(datos) => Agregar(datos)">
    <template v-if="$slots.nuevo" #nuevo>
      <slot name="nuevo"></slot>
    </template>
  </ModalNuevo>
</template>

<script>

import Idiomas from "@/api-services/idiomas.yacalificame.service";
import Card from "./Card.vue";
import CardEdicionIdioma from './CardEdicionIdioma.vue';
import ModalNuevo from '../../../Componentes/Listados/ModalNuevo.vue';

export default {
  name: "Tabla",
  components: {
    CardEdicionIdioma,
    //Card,
    ModalNuevo,
  },
  props: {
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    msgError: {
      type: String,
      default: "",
    },
    titulo: {
      type: String,
      default: "Listado General",
    },
    parrafo: {
      type: String,
      default: "Parrafo General",
    },
    baseGetsListado: {
      type: Object,
      default: () => {
        return {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 10,
          Filtro: "",
        };
      },
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    nuevoType: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  data() {
    return {
      info: [],
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
      IrAEdicion: true,
      MostrarNuevo: false,
      MuestraBotonEditar: false,
    };
  },
  emits: ["agregar", "actualiza", "editar", "borrar", "reactivar","guardar"],
  computed: {
    MostrarBorrar() {
      return this.RegistroABorrar !== null;
    },
    slots() {
      return Object.keys(this.$slots);
    },
  },
  beforeMount() {
    Idiomas.get(this, (response) => {
      console.debug("Esta es la info de Idiomas:", response);
      this.info = response.data;
      for (let index = 0; index < this.info.length; index++) {
        this.info[index].traducciones.forEach(traduc => {
        traduc.edicion = false
      });
        
      }
      
      this.cargando = false;
    }, (error) => {
      console.error("Error al obtener la información de idiomas:", error);
      this.MsgError = "Error al cargar la información de idiomas.";
      this.cargando = false;
    });
  },
  mounted() {
    this.Actualiza();
  },
  methods: {
    ActualizaDatosNuevo(Datos) {
      this.$emit("update:datos-nuevo", Datos);
    },
    Agregar(registro) {
      this.$emit("agregar", {
        registro: registro,
        OnAgregado: this.OnAgregado,
        OnError: this.OnError,
      });
      /*  console.log("Agregar", registro);
      this.$emit("agregar", registro);*/
    },
    Editar(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
      console.debug(registro.encuestaID);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
    OnAgregado(response) {
      var ID = response.data.id;
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.IrAEdicion && ID) this.$router.push({ path: `${this.$route.path}/${ID}` });
          else {
            this.Actualiza();
            this.MostrarNuevo = false;
          }
        });
      }, 100);
      /*
      this.$nextTick(() => {
        this.Actualiza();
        this.MostrarNuevo = false;
      });*/
    },
    //Actualiza datos asignando la pagina actual
    Actualiza(paginaNo) {
      if (paginaNo != null) {
        this.localBaseGetsListado.PaginaNo = paginaNo;
        
        this.$emit("actualiza", this.localBaseGetsListado);
      } else this.$emit("actualiza", this.localBaseGetsListado);
    },
    Editarfila(Fila){
      Fila.edicion = true
    },
    actualizaED(Fila,index,index2,tipo){
      console.debug("Este es el objeto",Fila)
     
      if (tipo == "Etiqueta") {
        this.info[index].traducciones[index2] = Fila
      }
      else {
        console.debug("si estoy entrando")
       this.info[index] = Fila
      }
    },
    CancelarEDetiqueta(Objeto){
      Objeto.edicion = false
    },
    EdicionActiva(Fila){
      if (Fila.edicion) {
        return true;
    }
    if (Array.isArray(Fila.traducciones)) {
        for (let traduccion of Fila.traducciones) {
            if (traduccion.edicion) {
                return true;
            }
        }
    }
    return false;
    },
   
    Guardar(Fila){
      this.$emit("guardar",Fila)
      this.QuitarBoton(Fila)
    },
    QuitarBoton(Fila){
      Fila.edicion = false
      if (Array.isArray(Fila.traducciones)) {
        for (let traduccion of Fila.traducciones) {
            traduccion.edicion = false
        }
    }
    },
  },
};
</script>

<style lang="scss" scoped>
table.container {
  font-weight: 200;
  font-style: normal;
}

.container {
  margin-top: 20px;
}
</style>
