<template>
  <ListadoGeneral
    class="cards"
    :cargando="cargando"
    :msg-error="MsgError"
    :info="info"
    titulo="Gafetes"
    tipo="gafetes"
    tipo-icono="fas fa-id-badge"
    parrafo="En esta sección podrás ver el listado de los gafetes."
    :base-gets-listado="BaseGetsListado"
    @actualiza="Actualiza"
    @agregar="Agregar"
    @editar="Editar"
    @borrar="Borrar"
    @reactivar="Reactivar"
    @update:datos-nuevo="(value) => (Datos = value)"
  >
    <template #listado>
      <div class="card-columns-listado">
        <TablaGafetes :registros="info.listado" />
      </div>
    </template>
  </ListadoGeneral>
</template>
  
<script>
import TablaGafetes from "@/Vistas/Gafetes/Components/TablaGafetes.vue";
import ListadoGeneral from "@/Componentes/ListadoGeneral.vue";
//import Servicio from "@/api-services/colaboradores.service";
import Servicio from "@/api-services/gafetes.yacalificame.service";


export default {
  name: "GafetesListado",
  components: {
    ListadoGeneral,
    TablaGafetes,
  },
  prop: {
    datos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
    };
  },
  methods: {
    Agregar(Parametros) {
      Servicio.post(
        this,
        Parametros.registro,
        (response) => {
          if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de borrar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de Reactivar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    Actualiza(value) {
      this.BaseGetsListado = value;
      Servicio.gets(this, value, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>