<!-- @format -->

<template>
  <div class="d-flex w-100 ">
    <MazSelect
      v-model="Seleccionado"
      :label="TipoMayuscula"
      :options="Listado"
      option-value-key="id"
      option-label-key="etiqueta"
      option-input-value-key="etiqueta"
      search-placeholder="Buscar..."
      search
      :required="required"
      class="flex-grow-1"
    >
      <template #left-icon>
        <i class="fas fa-tag"></i>
      </template>
      <template #right-icon>
        <i :class="tipoIcono">12</i>
      </template>
    </MazSelect>
    <a v-if="!required"  href="#" class="p-2 mx-2" @click="Seleccionado = null">
      <i class="fas fa-times"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "ListadosSelect",
  components: {
    /* ModalEditarColaborador, */
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      required: true,
    },
    funcion: {
      type: Function,
      required: true,
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      info: null,

      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      GetsResponse: null,
      Seleccionado: this.modelValue,
    };
  },
  computed: {
    TipoMayuscula() {
      return this.tipo.charAt(0).toUpperCase() + this.tipo.slice(1);
    },
    Listado() {
      if (this.info == null || this.info.listado == null) return [];
      return this.info.listado;
    },
  },

  watch: {
    modelValue(valor) {
      this.Seleccionado = valor;
      return;
    },
    Seleccionado(valor) {
      this.$emit("update:modelValue", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      if (this.service != null)
        this.service.getsSelect(this, (response) => {
          this.info = response.data;
        });
      if (this.funcion != null)
        this.funcion(this, (response) => {
          this.info = response.data;
        });
    },
  },
};
</script>
