<template>
  <div>

    <ListadoGeneral v-if="!VerPedidoDetalles" :cargando="cargando" :msg-error="MsgError" :info="info"
      titulo="Gafetes solicitados" tipo="Gafete" tipo-icono="" parrafo="" :PermiteAgregar="false" nuevo-type="large"
      :base-gets-listado="BaseGetsListado" @actualiza="Actualiza" @agregar="Agregar" @borrar="Borrar"
      @reactivar="Reactivar" @update:datos-nuevo="(value) => (Datos = value)">

      <template #listado="{}">
        <div class="card-columns-listado">
          <CardGafete v-for="registro in info.listado" :key="registro.id" :registro="registro"
            @CambiaEstado="CambiaEstado" @VerDetalles="VerDetalles" />
        </div>
        <p>{{ registro }}</p>
      </template>
    </ListadoGeneral>
    <detalles-pedido v-else :Pedido="PedidoDetallado" :cargando="cargando" @Cancelar="VerPedidoDetalles = false"
      @actualiza="Actualiza" @BorrarGafete="BorrarGafete" @Imprimir="Imprimir" />
    <Modal v-if="ModalEstatus == true" :show="ModalEstatus" modal-classes="modal-dialog-centered"
      @close="ModalEstatus = false">
      <template #header>

      </template>

      <template #body>
        <div v-if="TipoModal == 'RegistrarPago'" class="w-100">
          <div class="w-90 mx-auto d-flex flex-column justify-content-center">
            <h2 style="color: #2ecfb2;" class="text-center mb-3">
              Registrar Pago
            </h2>
            <div class="w-100 d-flex flex-row text-center mb-3">
              <div class="text-muted">Llena los datos para el cambio de estatus a: </div> &nbsp;
              <div style="color:#2ecfb2">Imprimir</div>
            </div>
            <MazInput v-model="NumCotizacion" placeholder="No. de cotización" color="secondary" class="w-100 mb-3"
              @update:model-value="(value) => NumCotizacion = value" />
            <button style="background-color: #2ecfb2; color: white" class="btn" @click="ActualizaEstado">Cambiar
              estatus</button>
          </div>
        </div>
        <div v-else-if="TipoModal == 'Imprimir'" class="w-100">
          <div class="w-90 mx-auto d-flex flex-column justify-content-center">
            <i style="color: #2ecfb2; font-size: 7em;"
              class="d-flex justify-content-center fas fa-question-circle fa-lg"></i>
            <h2 class="text-center mt-3 mb-3">
              ¿Deseas imprimir?
            </h2>
            <button style="background-color: #2ecfb2; color: white;" class="w-100 btn" @click="ActualizaEstado">Imprimir</button>
            <router-link class="text-center mb-3" :to="{}" @click="ModalEstatus = false"><i
                class="fa fa-chevron-left me-2 "></i>Cancelar</router-link>
          </div>
        </div>
        <div v-else-if="TipoModal == 'Verificar'" class="w-100">
          <div class="w-90 mx-auto d-flex flex-column justify-content-center">
            <i style="color: #2ecfb2; font-size: 7em" class="fas fa-check-circle d-flex justify-content-center"></i>
            <h3 class="text-muted mb-2">Gafetes:</h3>
            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              Calidad de impresión uniforme en todos los gafetes.
            </div>
            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              Sin manchas, borrones o áreas descoloridas.
            </div>
            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              Que los elementos del gafete estén alineados.
            </div>
            <h3 class="text-muted mt-3 mb-2">Datos de empaquetado:</h3>
            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              No. de pedido correcto
            </div>

            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              Direccion correcta a enviar
            </div>
            <div class="d-flex text-muted">
              <i style="color: #2ecfb2;" class="fas fa-check mt-1"></i>&nbsp;&nbsp;
              Nombre de quien recibe y su teléfono correcto
            </div>
            <MazInput v-model="RespVerificacion" placeholder="Responsable de verificación" color="secondary"
              class="w-100 mt-3 mb-3" @update:model-value="(value) => RespVerificacion = value" />
            <button style="background-color: #2ecfb2; color: white" class="btn"
              @click="ActualizaEstado">Verificado</button>
          </div>
        </div>
        <div v-else-if="TipoModal == `Entregar`" class="w-100">
          <div class="w-90 mx-auto d-flex flex-column justify-content-center">
            <h2 style="color: #2ecfb2;" class="text-center mb-3">
              Entregar
            </h2>
            <p>No. de pedido: {{ NumPedido }}</p>
            <div class="w-100 d-flex flex-row text-center mb-3">
              <div class="text-muted">Llena los datos para el cambio de estatus a: </div> &nbsp;
              <div style="color:#2ecfb2">Entregado</div>
            </div>
            <MazInput v-model="NombreRecibio" placeholder="Nombre de quien recibió" color="secondary"
              class="w-100 mt-3 mb-3" @update:model-value="(value) => NombreRecibio = value" />
            <button style="background-color: #2ecfb2; color: white" class="btn"
              @click="ActualizaEstado">Entregado</button>
          </div>
        </div>
      </template>
      <p><!--Vacio para generar--></p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es-mx");
import Pedidos from "@/api-services/pedidos.yacalificame.service";
import Gafetes from "@/api-services/gafetes.yacalificame.service";
import Impresiones from "@/api-services/impresiones.yacalificame.service";
import ListadoGeneral from '../../Componentes/ListadoGeneral.vue';
import CardGafete from "../../Componentes/Listados/CardGafete.vue";
import Modal from "@/Componentes/Modal.vue";
import DetallesPedido from './Components/DetallesPedido.vue';
export default {
  name: "ListaEstado",
  components: {
    ListadoGeneral, CardGafete, Modal,
    DetallesPedido,

  },
  props: {

  },
  data() {
    return {
      info: null,
      cargando: false,
      PedidoDetalleActivo:null,
      MsgError: "",
      TipoModal: "",
      GafeteID: null,
      NumCotizacion: "",
      RespVerificacion: "",
      NumPedido: null,
      NombreRecibio: "",
      PedidoDetallado: null,
      ModalEstatus: false,
      VerPedidoDetalles: false,
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
      BaseGetsListadoD: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },

    }
  },
  beforeMount() {
    this.Actualiza(this.BaseGetsListado)
  },
  mounted() {

  },
  methods: {
    Actualiza(Valor) {
      this.BaseGetsListado = Valor
      Pedidos.getsSelect(this, Valor, (response) => {
        console.debug("Estos son los gafetes pendientes", response)
        this.info = response.data
      })
    },
    CambiaEstado(GafeteEstado) {
      this.GafeteID = null
      this.TipoModal = ""
      this.NumPedido = null
      console.debug("Recibi esta info del gafete: ", GafeteEstado)
      this.GafeteID = GafeteEstado.id
      this.TipoModal = GafeteEstado.estado
      this.NumPedido = GafeteEstado.pedido
      this.ModalEstatus = true
    },
    ActualizaEstado() {
      let GafeteEstatus = {
        noFactura: this.NumCotizacion,
        receptor: this.NombreRecibio,
        verificador: this.RespVerificacion,
        fechaCambio: moment().format()
      }
      Pedidos.put(null, this.GafeteID, GafeteEstatus, (response) => {
        console.debug("Esta es del cambio de estatus: ", response.data)
        this.NumCotizacion = ""
        this.NombreRecibio = ""
        this.RespVerificacion = ""
        this.Actualiza(this.BaseGetsListado)
        this.ModalEstatus = false
      })
    },
    VerDetalles(id) {
      this.PedidoDetalleActivo = id
      Pedidos.getPedido(this, id, this.BaseGetsListadoD, (response) => {
        this.PedidoDetallado = response.data
        this.VerPedidoDetalles = true
      })
    },
    BorrarGafete(Datos) {
      let texto = {
        motivo:Datos.comentario
      }
      Gafetes.delete(this, Datos.gafete.id,texto, (response) => {
        console.debug("Se elimino la credencial", response)
        this.VerDetalles(this.PedidoDetalleActivo)
      })
    },
    Imprimir(ID){
      Impresiones.postImprime(this,ID,(response)=> {
        console.debug("Se imprimio este gafete ", response)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-columns-listado {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  gap: 20px;
}

@media (min-width: 10em) {
  .card {
    flex-basis: calc(100% - 14px) !important;
  }
}

@media (min-width: 35em) {
  .card {
    flex-basis: calc(100% / 2 - 14px) !important;
  }
}

@media (min-width: 65em) {
  .card {
    flex-basis: calc(100% / 3 - 14px) !important;
  }
}

@media (min-width: 97em) {
  .card {
    flex-basis: calc(100% / 4 - 14px) !important;
  }
}

@media (min-width: 114em) {
  .card {
    flex-basis: calc(100% / 5 - 14px) !important;
  }
}

@media (min-width: 130em) {
  .card {
    flex-basis: calc(100% / 6 - 14px) !important;
  }
}

@media (min-width: 150em) {
  .card {
    flex-basis: calc(100% / 7 - 14px) !important;
  }
}
</style>