<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link :to="{ name: 'Dashboards' }">
          <sidenav-collapse nav-text="Dashboards" :collapse="false" :aria-controls="''">
            <template #icon>
              <i style="." class="fa fa-chart-pie text-secondary text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <sidenav-collapse collapse-ref="applicationsExamples" nav-text="Personal"
          :class="getRoute() === 'applications' ? 'active' : ''">
          <template #icon>
            <i style="." class="fa fa-users text-secondary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item :to="{ name: 'Colaboradores' }" mini-icon="C" text="Colaboradores" />
              <sidenav-item :to="{ name: 'Departamentos' }" mini-icon="D" text="Departamentos" />
              <sidenav-item :to="{ name: 'Puestos' }" mini-icon="P" text="Puestos" />
              <sidenav-item :to="{ name: 'Gafetes' }" mini-icon="G" text="Gafetes" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Encuestas' }">
          <sidenav-collapse nav-text="Encuestas" :collapse="false" :aria-controls="''">
            <template #icon>
              <i style="." class="fa fa-file-signature text-secondary text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Sucursales' }">
          <sidenav-collapse nav-text="Sucursales" :collapse="false" :aria-controls="''">
            <template #icon>
              <i style="." class="fa fa-store text-secondary text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'GafetesLista'}">
          <sidenav-collapse
            nav-text="Gafetes"
            :collapse="false"
            :aria-controls="''"
          >
            <template #icon>
              <i
                style="."
                class="fa fa-file-invoice text-secondary text-sm opacity-10"
              ></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'ConfiguraciónIdioma' }">
          <sidenav-collapse nav-text="Idioma" :collapse="false" :aria-controls="''">
            <template #icon>
              <i class="fas fa-language text-secondary text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="mt-3 sidenav-footer">
    <sidenav-card :card="{
          title: '¿Necesitas ayuda?',
          description: '',
          links: [
            {
              label: 'Contáctanos',
              route:
                'https://support.eclock.com.mx/portal/es/newticket?departmentId=470109000029231029&layoutId=470109000029236462',
              color: 'success',
            },
          ],
        }" />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
  },
  data() {
    return {
      /*  menu: 'Dashboard', */
      selectedOption: "option1",
      isCollapsed: false,
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    updateMenu(selectedMenu) {
      this.menu = selectedMenu;
      this.$router.push(`/${selectedMenu.toLowerCase()}`);
    },
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>


<style lang="css" scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  padding-top: 20px;
  transition: width 0.5s;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  width: 100px;
}

.menu {
  list-style-type: none;
  padding: 0;
}

.menu li {
  padding: 10px 20px;
  border-bottom: 1px solid #555;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.submenu {
  display: none;
  list-style-type: none;
  padding-left: 20px;
}

.submenu li {
  padding: 5px 0;
}

.hamburger {
  position: fixed;
  left: 260px;
  top: 20px;
  background-color: #333;
  color: #fff;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.content {
  margin-left: 250px;
  transition: margin-left 0.5s;
}

.sidebar-collapsed .logo img {
  display: none;
}

.sidebar-collapsed .hamburger {
  display: block;
}

.content-collapsed {
  margin-left: 60px;
}
</style>
