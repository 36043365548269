/** @format */

import Axios from "axios";
import LiAuth from "@/api-services/liauth.service";
import BaseService from "@/api-services/base.service";
const USUARIOS = `${BaseService.URL}/usuarios`;
const SESIONES = `${BaseService.URL}/sesiones`;
import Ubicaciones from "@/api-services/ubicaciones.service";

import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";
import suscripcionesUsuariosService from "./suscripciones.usuarios.service";

export default {
  estaLogueado() {
    var Sesion = this.getSesion();
    return !(Sesion == null || Sesion == "");
  },
  getSesion() {
    return localStorage.sesion;
  },
  FotoUsuarioURL(Usuario) {
    var R = "img/theme/user512.png";
    //console.debug(Usuario);
    if (
      Usuario != undefined &&
      Usuario != null &&
      Usuario.urlFotoMiniatura != undefined &&
      Usuario.urlFotoMiniatura != null &&
      Usuario.urlFotoMiniatura != ""
    ) {
      R = Usuario.urlFotoMiniatura;
    }
    if (
      Usuario != undefined &&
      Usuario != null &&
      Usuario.UrlFotoMiniatura != undefined &&
      Usuario.UrlFotoMiniatura != null &&
      Usuario.UrlFotoMiniatura != ""
    ) {
      R = Usuario.UrlFotoMiniatura;
    }
    //console.debug(R);
    return R;
  },
  EsUsuarioActual(Usuario, OnFinalizado) {
    this.getPerfilLocal((PerfilLocal) => {
      /*console.debug(Usuario);
      console.debug(PerfilLocal.usuario);*/
      OnFinalizado(this.PerfilLocal.usuario.usuarioID == Usuario.usuarioID);
      //return ;
    });
  },
  ObtenUrlInvitacion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/ri?i=" + Reunion.reunionAlternoID;
  },
  ObtenUrlEdicion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/usuarios/edicion?i=" + Reunion.reunionAlternoID;
  },
  getUsuario(UsuarioID) {
    return Axios.get(USUARIOS + `/${UsuarioID}`, LiAuth.header());
  },
  getUsuarios() {
    return Axios.get(USUARIOS, LiAuth.header());
  },
  getPerfil() {
    return Axios.get(USUARIOS + "/Perfil", LiAuth.header());
  },
  postUsuario(data) {
    /*
     *data {
     *         [Required]
      public string Nombre { get; set; }
      /// <summary>
      /// Numero teléfonico con código de pais
      /// </summary>
      [Required]
      public string Telefono { get; set; }
      /// <summary>
      /// Correo electrónico
      /// </summary>
      [Required]
      public string Correo { get; set; }
      /// <summary>
      /// contraseña del usuario
      /// </summary>
      [Required]
      public string Password { get; set; }
      public string Traking { get; set; }}
     *
     *
     * */
    return Axios.post(USUARIOS, data, LiAuth.header());
  },
  /*        /// <summary>
        /// Correo electrónico o Número Teléfonico
        /// </summary>
        public string CorreoTelefono { get; set; }
        /// <summary>
        /// Contraseña del usuario
        /// </summary>
        public string Password { get; set; }

        /// <summary>
        /// Datos opcionales para ser almacenados en la sesión
        /// </summary>
        public string Traking { get; set; }*/
  postSesion(data) {
    /*        /// <summary>
          /// Correo electrónico o Número Teléfonico
          /// </summary>
          public string CorreoTelefono { get; set; }
          /// <summary>
          /// Contraseña del usuario
          /// </summary>
          public string Password { get; set; }
  
          /// <summary>
          /// Datos opcionales para ser almacenados en la sesión
          /// </summary>
          public string Traking { get; set; }*/
    return Axios.post(SESIONES, data, LiAuth.header());
  },
  cerrarSesion() {
    // return;
    localStorage.removeItem("sesion");
    localStorage.removeItem("perfilLocal");
    /*  localStorage.sesion = ""
    localStorage.perfilLocal = "";*/
  },
  PerfilLocal: null,
  getPerfilLocal(Finalizado, Actualiza) {
    if (this.PerfilLocal != null && Actualiza != true) {
      Finalizado(this.PerfilLocal);
      return;
    }
    if (this.PerfilLocal == null && Actualiza != true) {
      var Ls = localStorage.perfilLocal;
      if (Ls != null && Ls != "") {
        try {
          this.PerfilLocal = JSON.parse(Ls);
          suscripcionesUsuariosService.setSuscripcionesUsuario(this.PerfilLocal.usuario.suscripcionesUsuario);
          Finalizado(this.PerfilLocal);
        } catch {}
      }
    }
    setTimeout(() => {
      this.getPerfil()
        .then((response) => {
          this.PerfilLocal = response.data;
          localStorage.perfilLocal = JSON.stringify(this.PerfilLocal);
          suscripcionesUsuariosService.setSuscripcionesUsuario(this.PerfilLocal.usuario.suscripcionesUsuario);
          var suscripcionID = localStorage.suscripcionID;
          var SE = this.PerfilLocal.usuario.suscripcionesUsuario.filter((x) => x.suscripcionID == suscripcionID);
          if (SE.length < 1)
            localStorage.suscripcionID = this.PerfilLocal.usuario.suscripcionesUsuario[0].suscripcionID;

          Finalizado(this.PerfilLocal);
        })
        .catch((error) => {
          this.PerfilLocal = null;
          console.error(error);
        });
    }, 1500);
  },
  ///TimeO
  getPerfilSoloLocal(Evento, TimeOutMiliSegundos) {
    if (TimeOutMiliSegundos == null) TimeOutMiliSegundos = 1000;
    var Yo = this;
    if (this.PerfilLocal == null) {
      setTimeout(function () {
        Yo.getPerfilSoloLocal(Evento, TimeOutMiliSegundos);
      }, TimeOutMiliSegundos);
      return;
    }
    Evento(this.PerfilLocal);
  },
  put(Datos) {
    /*
     * Datos Posibles
     *   "nombre": "string",
     *   "correo": "string",
     *   "telefono": "string",
     *   "rfu": "string",
     *   "correoValidado": true,
     *   "telefonoValidado": true,
     *   "passwordAnterior": "string",
     *   "passwordNuevo": "string"*/

    return Axios.put(`${USUARIOS}`, Datos, LiAuth.header());
  },
  delete() {
    return Axios.delete(`${USUARIOS}/${this.PerfilLocal.usuario.usuarioID}`, LiAuth.header());
  },
  deleteSuscripcion(UsuarioID, SuscripcionID) {
    return Axios.delete(`${USUARIOS}/${UsuarioID}/Suscripciones/${SuscripcionID}`, LiAuth.header());
  },

  ValidacionPost(Datos) {
    /*
     * Datos = {nombre:"",correo:"",telefono:""}
     * */
    return Axios.post(`${USUARIOS}/Validacion`, Datos, LiAuth.header());
  },
  ValidacionPut(Datos) {
    /*
     *Datos = {verificacionID:"",codigo:""}
     * verificacionID correo o telefono a validar
     * codigo de validación
     * */
    return Axios.put(`${USUARIOS}/Validacion`, Datos, LiAuth.header());
  },
  /*
   *Envia un correo o SMS con el código de recuperación
   * */
  GetRecuperarPassword(CorreoOTelefono) {
    return Axios.get(`${USUARIOS}/${CorreoOTelefono}/RecuperarPassword`, LiAuth.header());
  },
  SuplantarPatch(Correo) {
    return Axios.patch(`${USUARIOS}/Suplantar`, { correo: Correo }, LiAuth.header());
  },

  /*  FotoPatch(Vista,  Orden, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Foto?Orden=${Orden}`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },*/
};
