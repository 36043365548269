<template>
  <div
    v-if="layout === 'custom'"
    class="position-absolute w-100 min-height-300 top-0"
    :style="{
      'background-image': 'url(' + image + ')',
      'background-position-y': '50%',
    }"
  >
    <span class="mask bg-success opacity-6"></span>
  </div>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="darkMode ? 'bg-trasnparent' : 'bg-success'"
  />

  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${
      isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
    } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
  >
  <div class="  w-100  d-flex d-lg-none justify-content-center mt-3 sidenav-toggler sidenav-toggler-inner me-2">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="navbarMinimize">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line opacity-8 bg-dark" ></i>
            <i class="sidenav-toggler-line opacity-8 bg-dark" ></i>
            <i class="sidenav-toggler-line opacity-8 bg-dark" ></i>
          </div>
        </a>
      </div>
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        
      ></i>
      <router-link
        class="m-0 navbar-brand d-flex justify-content-center"
        to="/"
      >
        <img
          :src="logo"
          class="navbar-brand-img h-100 logo-Completo"
          alt="main_logo"
        />
        
        <img
          :src="logoIsotipo"
          class="navbar-brand-img h-100 logo-Isotipo "
          alt="main_logo"
        />
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logosya/Logoyacalifica767x199.png";
import logoIsotipo from "@/assets/img/logosya/Isotipo32x32.png";
import image from "@/assets/img/profile-layout-header.jpg";

import { mapState,mapMutations } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoIsotipo,
      image,
      
    };
  },
  computed: {
    ...mapState(["layout", "isRTL", "sidebarType", "darkMode"]),
  },

  beforeUnmount() {
    this.$store.state.image;
  },
  methods:{
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
  }
};
</script>
<style scoped>
.g-sidenav-show.g-sidenav-pinned .logo-Isotipo {
  display: none; /* Oculta el isotipo por defecto */
}

.g-sidenav-show.g-sidenav-hidden .logo-Isotipo {
  display: block; /* Muestra el isotipo cuando la clase g-sidenav-pinned está presente */
}
.g-sidenav-show .logo-Completo {
  display: none; /* Oculta el isotipo por defecto */
}

.g-sidenav-show.g-sidenav-pinned .logo-Completo {
  display: block; /* Muestra el isotipo cuando la clase g-sidenav-pinned está presente */
}
.icon-sm{
  width: auto;
  height: auto;
}
</style>