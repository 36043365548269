<!-- @format -->

<template>
  <Modal
    v-if="MostrarModal"
    :show="MostrarModal"
    modal-classes="modal-dialog-centered"
    :type="type"
    @close="closeModal"
  >
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-title text-center"><i class="pe-2 fas fa-filter"></i>Actualizar Filtro</h5>
    </template>

    <template #body>
      <div class="p-3">
        <Cargando :cargando="cargando" :msg-error="msgError">
          <form @submit.prevent="Agregar">
            <div class="d-grid gap-1">
              <div>
                <registros-x-pagina
                  :value="BaseGetsListado.PaginaLen"
                  @update="
                    (value) => {
                      BaseGetsListado.PaginaLen = value;
                    }
                  "
                />
                <SelectPersonal v-if="!BaseGetsListado.SoloMios" v-model="BaseGetsListado.ColaboradorID" class="mb-campo" color="secondary" />
                <MazCheckbox v-model="SoloActivos" class="mb-3"> Mostrar solo tickets activos </MazCheckbox>
              </div>
              <hr />

              <button class="btn btn-success mb-2" type="submit">Enviar</button>
            </div>
          </form>
        </Cargando>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
import Cargando from "@/Componentes/Cargando.vue";
import RegistrosXPagina from "@/Componentes/Listados/RegistrosXPagina.vue";
import SelectPersonal from "@/Vistas/Personal/Select.vue";
export default {
  components: {
    Modal,
    Cargando,
    RegistrosXPagina,
    SelectPersonal,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    //Muestra que esta cargando
    cargando: { type: Boolean, default: true },

    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", "update:ir-a-edicion", "update:modelValue"],

  data() {
    return {
      IrAEdicion: true,
      MostrarModal: this.mostrar,

      Datos: {
        comentario: "",
        archivos: [],
      },
      BaseGetsListado: this.modelValue == null ? {} : JSON.parse(JSON.stringify(this.modelValue)),
    };
  },

  computed: {
    SoloActivos: {
      get() {
        return this.BaseGetsListado.Activos;
      },
      set(value) {
        if (value) {
          this.BaseGetsListado.Activos = true;
        } else {
          this.BaseGetsListado.Activos = null;
        }
      },
    },
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {

    modelValue(valor) {
      this.BaseGetsListado = this.modelValue == null ? {} : JSON.parse(JSON.stringify(this.modelValue));
      return;
    },
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },

    Agregar() {

      this.$emit("update:modelValue", this.BaseGetsListado);
      this.closeModal();
    },
  },
};
</script>
