// dashboardServices.js

import Base from "@/api-services/base";
import YaCalificaMe from "@/api-services/eTickets.service.js";

const baseUrls = {
  genero: `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Dashboards/Genero`,
  retorno: `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Dashboards/Retorno`,
  visitas: `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Dashboards/Visitas`,
  evaluaciones: `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Dashboards/Evaluaciones`,
  calificaciones: `${YaCalificaMe.URL}/Suscripciones/{{SuscripcionID}}/Dashboards/Calificaciones`
};

export default {
  getsGeneros(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    const baseURL = baseUrls.genero;
    return Base.getsBaseSuscripcion(
      Vista,
      baseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsRetornos(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    const baseURL = baseUrls.retorno;
    return Base.getsBaseSuscripcion(
      Vista,
      baseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsVisitas(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    const baseURL = baseUrls.visitas;
    return Base.getsBaseSuscripcion(
      Vista,
      baseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsEvaluaciones(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    const baseURL = baseUrls.evaluaciones;
    return Base.getsBaseSuscripcion(
      Vista,
      baseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsCalificaciones(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    const baseURL = baseUrls.calificaciones;
    return Base.getsBaseSuscripcion(
      Vista,
      baseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  }
};
