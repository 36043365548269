<template>
  <div>
    <li class="nav-item dropdown d-flex align-items-center" :class="isRTL ? 'ps-2' : 'pe-2'">
      <a id="dropdownMenuButton" href="#" :class="`p-0 nav-link ${isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
      } ${showMenu ? 'show' : ''}`" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
        <i class="cursor-pointer fa fa-plus text-primary"></i>
      </a>
      <div style="overflow-y: auto; max-height: 40vh;" class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
        :class="showMenu ? 'show' : ''" aria-labelledby="dropdownMenuButton">

        <MazInput class="mb-5" v-model="Busqueda" color="secondary" left-icon="banknotes" placeholder="">

          <template #left-icon>
            <div class="">
              <i class="fas fa-search p-2"></i>
            </div>

          </template>
        </MazInput>

        <button v-for="Pais in PaisesDispo" :key="Pais.iso" class="btn  w-100" @click="ElegirPais(Pais)">
          {{ Pais.Bandera + ' ' + Pais.Nombre }}
        </button>

      </div>

    </li>
  </div>
</template>

<script>

import { countryCodeToUnicodeFlag } from "maz-ui";
import { getCountries } from 'libphonenumber-js'

export default {
  name: "NavbarIdioma",
  components: {},
  props: {
    Idioma: { type: String, default: "" },
    Info: { type: Object, default: null },
  },
  emits: ["AgregarIdioma"],
  data() {
    return {
      Preguntas: [],
      ListaPaises: [],
      PaisesDispo:[],
      Busqueda: null,
    };
  },
  watch: {
    Busqueda(Valor){
      console.debug("Este es el valor", Valor)
      if (Valor == null || Valor == "") {
        this.PaisesDispo = structuredClone(this.ListaPaises)
      }

      this.PaisesDispo = this.ListaPaises.filter(x => x.Nombre.includes(Valor) )
    },
  },
  beforeMount() {
    this.ObtenPaises(window.navigator.language)
    /* for (let index = 0; index < this.Info.length; index++) {
      let pregunta = this.Info[index].contenido.filter(
        (x) => x.idioma == this.Idioma
      )[0];
      this.Preguntas.push(pregunta);
    } */
  },
  mounted() { },
  methods: {

    ObtenPaises(Idioma) {
      let iso = getCountries()
      for (let index = 0; index < iso.length; index++) {
        let Pais = new Intl.DisplayNames([Idioma ?? 'es-ES'], { type: 'region' }).of(iso[index])
        if (Pais) {
          this.ListaPaises.push({
            iso: iso[index] + "-" + iso[index],
            Nombre: Pais,
            Bandera: countryCodeToUnicodeFlag(iso[index])
          })
        }
      }
      this.PaisesDispo = structuredClone(this.ListaPaises)
    },

    ElegirPais(Pais){
      console.debug("El pais elegido es:", Pais)
      this.$emit("AgregarIdioma",Pais.iso)
    },
    /* ObtenBandera(Pais) {
      if (Pais == "es-ES") {
        let bandera = "MX";
        return countryCodeToUnicodeFlag(bandera);
      }

      let bandera2 = Pais.split("-")[1];
      return countryCodeToUnicodeFlag(bandera2);
    }, */
  },
};
</script>

<style lang="scss" scoped></style>